import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const CarAccount = lazy(() => import('@/modules/placeManagement/carAccount'));
const ArrivalStore = lazy(() => import('@/modules/placeManagement/arrivalStore'));
const RidingList = lazy(() => import('@/modules/placeManagement/ridingList'));
const CarStandard = lazy(() => import('@/modules/placeManagement/carStandard'));
const CarStatistics = lazy(() => import('@/modules/placeManagement/carStatistics'));
const CarOrder = lazy(() => import('@/modules/placeManagement/carOrder'));
const PointSetting = lazy(() => import('@/modules/placeManagement/pointSetting'));
const PassengerList = lazy(() => import('@/modules/placeManagement/passengerList'));
const AirborneList = lazy(() => import('@/modules/placeManagement/airborneList'));
export default {
  path: 'placeManagement',
  element: <Outlet />,
  options: {
    title: '安置管理',
    permissionKey: 'menu-placeManagement',
    key: 'menu-placeManagement',
    icon: <Icon.ContactsOutlined />,
    link: '/placeManagement',
    // allowAccess: true,
  },
  children: [
    {
      path: 'pointSetting',
      element: <Outlet />,
      options: {
        title: '集合点设置',
        key: 'menu-placeManagement-pointSetting',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/pointSetting',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="集合点设置" permissionKey="menu-placeManagement-pointSetting" component={<PointSetting />} />,
        },
      ],
    }, {
      path: 'arrivalStore',
      element: <Outlet />,
      options: {
        title: '到店登记',
        key: 'menu-placeManagement-arrivalStore',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/arrivalStore',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="到店登记" permissionKey="menu-placeManagement-arrivalStore" component={<ArrivalStore />} />,
        },
      ],
    }, {
      path: 'ridingList',
      element: <Outlet />,
      options: {
        title: '乘车刷身份证',
        key: 'menu-placeManagement-ridingList',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/ridingList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="乘车刷身份证" permissionKey="menu-placeManagement-ridingList" component={<RidingList />} />,
        },
      ],
    },
    {
      path: 'carStandard',
      element: <Outlet />,
      options: {
        title: '车费标准',
        key: 'menu-placeManagement-carStandard',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/carStandard',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="车费标准" permissionKey="menu-placeManagement-carStandard" component={<CarStandard />} />,
        },
      ],
    }, {
      path: 'carStatistics',
      element: <Outlet />,
      options: {
        title: '车辆费用',
        key: 'menu-placeManagement-carStatistics',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/carStatistics',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="车辆费用" permissionKey="menu-placeManagement-carStatistics" component={<CarStatistics />} />,
        },
      ],
    }, {
      path: 'carOrder',
      element: <Outlet />,
      options: {
        title: '用车订单',
        key: 'menu-placeManagement-carOrder',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/carOrder',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="用车订单" permissionKey="menu-placeManagement-carOrder" component={<CarOrder />} />,
        },
      ],
    }, {
      path: 'carAccount',
      element: <Outlet />,
      options: {
        title: '车辆台账',
        key: 'menu-placeManagement-carAccount',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/carAccount',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="车辆台账" permissionKey="menu-placeManagement-carAccount" component={<CarAccount />} />,
        },
      ],
    }, {
      path: 'passengerList',
      element: <Outlet />,
      options: {
        title: '安置名单',
        key: 'menu-placeManagement-passengerList',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/passengerList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="安置名单" permissionKey="menu-placeManagement-passengerList" component={<PassengerList />} />,
        },
      ],
    }, {
      path: 'airborneList',
      element: <Outlet />,
      options: {
        title: '空降名单',
        key: 'menu-placeManagement-airborneList',
        icon: <Icon.FileOutlined />,
        link: '/placeManagement/airborneList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="空降名单" permissionKey="menu-placeManagement-airborneList" component={<AirborneList />} />,
        },
      ],
    },
  ],
};
