/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Button, Drawer, message, Space, Progress, Table, Tooltip, Popover,
} from 'antd';
import {
  httpRequest, awardHttp, contractHttp, attendanceHttp, accountHttp,
} from '@utils';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import moment from 'moment';
import authStore from '@stores/auth';
import { datetimeFormat } from '@/constants';

const generateColumns = ({ onParseQueryParams, onDownload }) => [
  {
    title: '任务ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: '导出时间',
    dataIndex: 'timestamp',
    key: 'timestamp',
    width: 150,
    render: (text) => (text ? moment(text).format(datetimeFormat.dateTime) : null),
  },
  {
    title: '查询条件',
    dataIndex: 'search',
    key: 'search',
    ellipsis: true,
    width: 200,
    render: (text) => (
      <Popover content={onParseQueryParams(text)}>
        {onParseQueryParams(text)}
      </Popover>
    ),
  },
  {
    title: '导出进度',
    dataIndex: 'progress',
    key: 'progress',
    width: 100,
    render: (text, record) => (
      <Progress
        strokeColor={{
          from: '#108ee9',
          to: '#87d068',
        }}
        percent={record.progress * 100}
        size="small"
        status={record.status === 'failure' ? 'exception' : 'active'}
      />
    ),
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 100,
    render: (text, record) => {
      if (record.progress === 1) {
        return (
          <Button
            type="link"
            onClick={() => onDownload(record)}
          >
            下载到本地
          </Button>
        );
      }
      return null;
    },
  }];
const ExportButton = ({
  permissionKey, title, btnName, httpType, exportType, exportUrl, exportParams, parseQueryParams,
}) => {
  const columns = generateColumns({
    onParseQueryParams: (text) => parseQueryParams(text),
    onDownload: (record) => {
      window.open(record.url, '_blank');
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataContent, setDataContent] = useState(false); // 导出记录数据
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [time, setTime] = useState(null);
  const [searchCriteriaDrawer, setSearchCriteriaDrawer] = useState({ exportType, pageNumber: 0, pageSize: 10 });

  const getCallbackByModoule = async (search) => {
    let res;
    switch (httpType) {
      case 'backendHttp':
        res = await httpRequest.post('/common/export/tasks', search);
        break;
      case 'contractHttp':
        res = await contractHttp.post('/common/export/tasks', search);
        break;
      case 'attendanceHttp':
        res = await attendanceHttp.post('/common/export/tasks', search);
        break;
      case 'accountHttp':
        res = await accountHttp.post('/common/export/tasks', search);
        break;
      case 'awardHttp':
        res = await awardHttp.post('/common/export/tasks', search);
        break;
      default:
        break;
    }
    return res;
  };

  // 获取导出记录
  const getExportData = async (search) => {
    try {
      setIsLoading(true);
      const res = await getCallbackByModoule(search);
      if (res.code === 0) {
        setDataContent(res.data.content);
        setSearchCriteriaDrawer({ ...searchCriteriaDrawer, total: res.data.total });
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      message.error(err?.message || '获取导出记录失败');
    }
  };

  const searchDrawer = { ...searchCriteriaDrawer, total: 0 };
  const {
    data,
    isError,
    error,
    refetch,
  } = useQuery([exportType, searchDrawer], () => getExportData(searchDrawer), { enabled: visibleDrawer });
  if (isError) {
    message.error(error.message);
  }

  const onDrawerClose = () => {
    setVisibleDrawer(false);
    clearInterval(time);
  };

  const showExportData = () => {
    setVisibleDrawer(true);
    // getExportData(searchDrawer);
    // 打开定时器
    setTime(setInterval(refetch, 3000));
  };

  // 导出数据请求
  const exportDataRequest = async () => {
    try {
      let res;
      switch (httpType) {
        case 'awardHttp':
          res = await awardHttp.post(exportUrl, exportParams);
          break;
        case 'contractHttp':
          res = await contractHttp.post(exportUrl, exportParams);
          break;
        case 'attendanceHttp':
          res = await attendanceHttp.post(exportUrl, exportParams);
          break;
        case 'accountHttp':
          res = await accountHttp.post(exportUrl, exportParams);
          break;
        default:
          res = await httpRequest.post(exportUrl, exportParams);
      }
      if (res.code === 0) {
        message.success('请求成功，请查看导出记录');
        showExportData();
      }
    } catch (err) {
      message.error(err?.message || '接口发生错误');
    }
  };

  const rowSelection = {
    onCell: () => ({
      style: {
        maxWidth: 100,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
      },
    }),
  };

  const handleTableChange = (params) => {
    setSearchCriteriaDrawer({
      ...params, pageNumber: params.current - 1, exportType,
    });
  };

  if (permissionKey !== '' && authStore.permissions.indexOf(permissionKey) === -1) {
    return '';
  }
  return (
    <>
      <Button onClick={exportDataRequest}>{btnName}</Button>
      <Button type="link" size="small" onClick={showExportData}>
        {btnName}
        记录
      </Button>
      <Drawer
        title={`${title}-导出记录`}
        placement="right"
        size="large"
        onClose={onDrawerClose}
        visible={visibleDrawer}
        extra={(
          <Space>
            <Button onClick={onDrawerClose}>关闭</Button>
          </Space>
        )}
      >
        <Table
          bordered
          size="small"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={dataContent}
          pagination={{ ...searchCriteriaDrawer }}
          onChange={handleTableChange}
          rowSelection={rowSelection}
        />
      </Drawer>
    </>
  );
};

ExportButton.propTypes = {
  permissionKey: PropTypes.string, // 按钮所需权限
  title: PropTypes.string, // 标题
  btnName: PropTypes.string, // 标题
  exportType: PropTypes.string.isRequired, // 导出类型，
  httpType: PropTypes.string, // 请求类型，
  exportUrl: PropTypes.string.isRequired, // 导出请求的URL
  exportParams: PropTypes.shape.isRequired, // 导出的请求的参数，需要被回显到记录中
  parseQueryParams: PropTypes.func, // 解析回调函数，由对应的调用方自己去解析
};

ExportButton.defaultProps = {
  permissionKey: '',
  httpType: 'backendHttp',
  title: '导出记录',
  btnName: '导出',
  parseQueryParams: () => { },
};

export default ExportButton;
