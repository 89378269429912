import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const CompanyList = lazy(() => import('@/modules/businessAffairs/companyOrderManage/company/list'));
const CompanyCreact = lazy(() => import('@/modules/businessAffairs/companyOrderManage/company/companyCreact'));
const CompanyEdit = lazy(() => import('@/modules/businessAffairs/companyOrderManage/company/companyEdit'));
const OrderManagement = lazy(() => import('@/modules/businessAffairs/companyOrderManage/order/orderManagement/orderManagement'));
const OrderEdit = lazy(() => import('@/modules/businessAffairs/companyOrderManage/order/orderEdit'));
const OrderNew = lazy(() => import('@/modules/businessAffairs/companyOrderManage/order/orderNew'));
const CustomerVisit = lazy(() => import('@/modules/businessAffairs/customerVisit'));
const MyMembers = lazy(() => import('@/modules/businessAffairs/memberManage/myMembers'));
const EditMember = lazy(() => import('@/modules/businessAffairs/memberManage/editMember'));
const BlackList = lazy(() => import('@/modules/businessAffairs/memberManage/black'));
const SupplierList = lazy(() => import('@/modules/businessAffairs/supplierManage/supplier'));
const SupplierOrder = lazy(() => import('@/modules/businessAffairs/supplierManage/order/supplierOrderManagement/orderManagement'));
const SupplierOrderEdit = lazy(() => import('@/modules/businessAffairs/supplierManage/order/orderEdit'));
const SupplierOrderNew = lazy(() => import('@/modules/businessAffairs/supplierManage/order/orderNew'));
export default {
  path: 'businessAffairs',
  element: <Outlet />,
  options: {
    title: '客户管理',
    permissionKey: 'menu-affairsManage',
    key: 'menu-affairsManage',
    icon: <Icon.WhatsAppOutlined />,
    link: '/businessAffairs',
    allowAccess: true,
  },
  children: [
    {
      path: 'memberManage',
      element: <Outlet />,
      options: {
        title: '会员管理',
        key: 'menu-businessAffairs-memberManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/businessAffairs/memberManage',
        allowAccess: true,
      },
      children: [
        {
          path: 'myMembers',
          element: <Outlet />,
          options: {
            title: '我的会员',
            key: 'menu-recruitManagement-myMembers',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/memberManage/myMembers',
            allowAccess: true,
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="我的会员" permissionKey="menu-recruitManagement-myMembers" component={<MyMembers />} />,
            },
            {
              path: ':poolId/edit',
              element: <ProtectedRoute title="编辑会员" permissionKey="menu-recruitManagement-myMembers" component={<EditMember />} />,
            },
          ],
        },
        {
          path: 'blackManagement',
          element: <Outlet />,
          allowAccess: true,
          options: {
            title: '白名单管理',
            key: 'menu-baseInfo-blackManage',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/memberManage/blackManagement',
            allowAccess: true,
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute permissionKey="menu-baseInfo-blackManage" title="白名单管理" component={<BlackList />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'companyOrderManage',
      element: <Outlet />,
      options: {
        title: '企业订单管理',
        key: 'menu-businessAffairs-companyOrderManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/businessAffairs/companyOrderManage',
        allowAccess: true,
      },
      children: [
        {
          path: 'company',
          element: <Outlet />,
          options: {
            title: '企业管理',
            key: 'menu-affairsManage-companyManage',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/companyOrderManage/company',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="企业管理" permissionKey="menu-affairsManage-companyManage" component={<CompanyList />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增企业" permissionKey="menu-affairsManage-companyManage" component={<CompanyCreact />} />,
            },
            {
              path: ':companyId/edit',
              element: <ProtectedRoute title="编辑企业" permissionKey="menu-affairsManage-companyManage" component={<CompanyEdit />} />,
            },
          ],
        },
        {
          path: 'orderManagement',
          element: <Outlet />,
          options: {
            title: '订单管理',
            key: 'menu-affairsManage-orderManage',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/companyOrderManage/orderManagement',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="订单管理" permissionKey="menu-affairsManage-orderManage" component={<OrderManagement />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增订单" permissionKey="menu-affairsManage-orderManage" component={<OrderNew />} />,
            },
            {
              path: ':orderId/edit',
              element: <ProtectedRoute title="编辑订单" permissionKey="menu-affairsManage-orderManage" component={<OrderEdit />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'supplierManage',
      element: <Outlet />,
      options: {
        title: '供应商订单管理',
        key: 'menu-businessAffairs-supplierManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/businessAffairs/supplierManage',
        allowAccess: true,
      },
      children: [
        {
          path: 'list',
          element: <Outlet />,
          options: {
            title: '供应商',
            key: 'menu-supplier-list',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/supplierManage/list',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="供应商" permissionKey="menu-supplier-list" component={<SupplierList />} />,
            },
          ],
        },
        {
          path: 'supplierOrderManagement',
          element: <Outlet />,
          options: {
            title: '供应商订单',
            key: 'menu-supplier-orderManage',
            icon: <Icon.FileOutlined />,
            link: '/businessAffairs/supplierManage/supplierOrderManagement',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="供应商订单管理" permissionKey="menu-supplier-orderManage" component={<SupplierOrder />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增供应商订单" permissionKey="menu-supplier-orderManage" component={<SupplierOrderNew />} />,
            },
            {
              path: ':orderId/edit',
              element: <ProtectedRoute title="编辑供应商订单" permissionKey="menu-supplier-orderManage" component={<SupplierOrderEdit />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'customerVisit',
      element: <Outlet />,
      options: {
        title: '客户拜访',
        key: 'menu-affairsManage-customerVisit',
        icon: <Icon.FileOutlined />,
        link: '/businessAffairs/customerVisit',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="客户拜访" permissionKey="menu-affairsManage-customerVisit" component={<CustomerVisit />} />,
        },
      ],
    },
  ],
};
