import { makeAutoObservable, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { accountHttp } from '@/utils';
import { storageKeys } from '@/constants';

const hasLoggedIn = !!Cookies.get(storageKeys.token);
const initPermissions = localStorage.getItem(storageKeys.permissions) ? JSON.parse(localStorage.getItem(storageKeys.permissions)) : [];

class Auth {
  profile = {
    account: '',
    source: '',
    tenantId: null,
    tenantName: '',
    username: '',
    avatar: '',
    isAdmin: false,
  };

  isLoggedIn = hasLoggedIn;

  permissions = initPermissions;

  constructor() {
    makeAutoObservable(this);
    if (hasLoggedIn) {
      this.getProfile();
      this.getPermission();
    }
  }

  setProfile(account, source, tenantId, tenantName, username, avatar, isAdmin) {
    this.profile.account = account;
    this.profile.source = source;
    this.profile.tenantId = tenantId;
    this.profile.tenantName = tenantName;
    this.profile.username = username;
    this.profile.avatar = avatar;
    this.profile.isAdmin = isAdmin;
  }

  async getProfile() {
    try {
      const userInfoRes = await accountHttp.get('/admin/account/currentUser/info');
      if (userInfoRes.code === 0) {
        runInAction(() => {
          const { data } = userInfoRes;
          this.setProfile(
            data.account,
            data.source,
            data.tenantId,
            data.tenantName,
            data.userName,
            process.env.REACT_APP_DEFAULT_AVATAR_URL, // 暂时还没有头像
            data.admin,
          );
        });
      }
    } catch (err) {
      console.log(err?.message || '获取用户信息失败');
    }
  }

  async getPermission() {
    try {
      const permissionRes = await accountHttp.get('/admin/userPermission');
      if (permissionRes.code === 0) {
        runInAction(() => {
          const permissions = permissionRes.data || [];
          this.permissions = permissions;
          localStorage.setItem(storageKeys.permissions, JSON.stringify(permissions));
        });
      } else {
        this.permissions = [];
      }
    } catch (err) {
      this.permissions = [];
      console.log(err?.message || '获取权限失败');
    }
  }

  clearlogInfo() {
    this.profile = {
      account: '',
      source: '',
      username: '',
      tenantId: null,
      tenantName: '',
      avatar: '',
      isAdmin: false,
    };
    this.isLoggedIn = false;
    this.permissions = [];
  }
}

export default new Auth();
