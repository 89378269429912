import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const CheckedOutWarning = lazy(() => import('@/modules/warnManage/checkedOutWarning'));
const WriteOffWarning = lazy(() => import('@/modules/warnManage/writeOffWarning'));
const UnsignContractWarning = lazy(() => import('@/modules/warnManage/unsignContractWarning'));
export default {
  path: 'warnManage',
  element: <Outlet />,
  options: {
    title: '预警管理',
    permissionKey: 'menu-warnManage',
    key: 'menu-warnManage',
    icon: <Icon.ContactsOutlined />,
    link: '/warnManage',
    // allowAccess: true,
  },
  children: [
    {
      path: 'checkedOutWarning',
      element: <Outlet />,
      options: {
        title: '未退宿预警',
        key: 'menu-warnManage-checkedOutWarning',
        icon: <Icon.FileOutlined />,
        link: '/warnManage/checkedOutWarning',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="未退宿预警" permissionKey="menu-warnManage-checkedOutWarning" component={<CheckedOutWarning />} />,
        },
      ],
    }, {
      path: 'writeOffWarning',
      element: <Outlet />,
      options: {
        title: '待冲销预警',
        key: 'menu-warnManage-writeOffWarning',
        icon: <Icon.FileOutlined />,
        link: '/warnManage/writeOffWarning',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="待冲销预警" permissionKey="menu-warnManage-writeOffWarning" component={<WriteOffWarning />} />,
        },
      ],
    }, {
      path: 'unsignContractWarning',
      element: <Outlet />,
      options: {
        title: '未签合同预警',
        key: 'menu-warnManage-unsignContractWarning',
        icon: <Icon.FileOutlined />,
        link: '/warnManage/unsignContractWarning',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="未签合同预警" permissionKey="menu-warnManage-unsignContractWarning" component={<UnsignContractWarning />} />,
        },
      ],
    },
  ],
};
