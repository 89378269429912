/*
 * @Author: TANG
 * @Date: 2022-06-25 10:37:54
 * @LastEditors: TANG 15390913806@163.com
 * @LastEditTime: 2022-06-25 10:40:49
 */
/**
 * 这是针对验证表单的正则表达式库
 */

/**
 * 是否不是正整数
 */
export const isNotPositiveInteger = (value) => (typeof value !== 'number' || !/^(([1-9][0-9]*))$/.test(value));

/**
  * 是否是正整数
  */
export const isPositiveInteger = (value) => /^(([1-9][0-9]*))$/.test(value);

/**
  * 是否不是正数（两位小数）
  */
export const isNotPositiveDecimal = (value) => (typeof value !== 'number' || !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value));

/**
  * 是否不是非负数（两位小数）
  */
export const isNotNonNegativeDecimal = (value) => (typeof value !== 'number' || !/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[0-9][0-9]*\.\d{1,2})))$/.test(value));

/**
  * 是否是是正数（两位小数）
  */
export const isPositiveDecimal = (value) => /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value);

/**
  * 是否是手机号
  */
export const isPhoneNumber = (value) => /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/.test(value);

// 校验全一点
export const isPhoneNumber2 = (phone) => {
  if (!phone) {
    return false;
  }
  return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phone);
};

// 身份证正则校验
export const idCardNoCheck = (idNo) => {
  if (!idNo) {
    return false;
  }
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(idNo);
};

// 身份证校验
export const idNoFormValidator = (rule, value, callback) => {
  if (!value) {
    callback('请输入身份证');
  }
  const res = idCardNoCheck(value);
  if (!res) {
    callback('身份证格式不正确');
  } else {
    callback();
  }
};

// 手机号校验
export const phoneFormValidator = (rule, value, callback) => {
  if (!value) {
    callback('请输入手机号');
  }
  const res = isPhoneNumber2(value);
  if (!res) {
    callback('手机号格式不正确');
  } else {
    callback();
  }
};

// 银行卡号校验
export const validateBankCard = (rule, value, callback) => {
  // 定义银行卡号的正则表达式规则
  const regex = /^[1-9]\d{9,29}$/;
  if (!value) {
    callback('请输入银行卡号');
  }
  const res = regex.test(value);
  if (!res) {
    callback('银行卡号格式不正确');
  } else {
    callback();
  }
};

// 车牌号校验
export const validateCarNo = (rule, value, callback) => {
  const regex = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
  if (!value) {
    callback('请输入车牌号');
  }
  const res = regex.test(value);
  if (!res) {
    callback('车牌号格式不正确');
  } else {
    callback();
  }
};

// 筛选栏多选字段遍历
export const findLabelFun = (arry1, arry2) => {
  if (arry2 !== null && arry2 instanceof Array) {
    const newArry = [];
    arry1?.map((item) => {
      arry2?.map((key) => {
        if (item.value === key) {
          newArry.push(item.label);
        }
        return key;
      });
      return item;
    });
    return newArry.toString();
  }
  return null;
};
