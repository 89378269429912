import React, { lazy } from 'react';
import dashboard from '@modules/dashboard';
import setting from '@modules/setting';
import businessAffairs from '@modules/businessAffairs';
import recruitmentManage from '@modules/recruitmentManage';
import recruitManagement from '@modules/recruitManagement';
import placeManagement from '@modules/placeManagement';
import financialManage from '@modules/financialManage';
import dormitoryManage from '@modules/dormitoryManage';
import contractMannage from '@modules/contractMannage';
import warnManage from '@/modules/warnManage';
import salaryManage from '@/modules/salaryManage';
import attendanceManage from '@/modules/attendanceManage';

const NotAuthorized = lazy(() => import('@modules/error/notAuthorized'));

export default [
  dashboard,
  setting,
  businessAffairs,
  recruitManagement,
  recruitmentManage,
  placeManagement,
  financialManage,
  dormitoryManage,
  contractMannage,
  warnManage,
  attendanceManage,
  salaryManage,
  {
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
];
