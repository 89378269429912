import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const AccountOverview = lazy(() => import('@/modules/salaryManage/accountOverview'));
const SalarySetting = lazy(() => import('@/modules/salaryManage/salarySetting'));
const SalayLists = lazy(() => import('@/modules/salaryManage/deliveryManage/salayList'));
const SalaryReview = lazy(() => import('@/modules/salaryManage/deliveryManage/salaryReview'));
const SalaryRecords = lazy(() => import('@/modules/salaryManage/deliveryManage/salaryRecords'));
const SalaryDetail = lazy(() => import('@/modules/salaryManage/deliveryManage/salaryDetails'));
const DeductionRecord = lazy(() => import('@/modules/salaryManage/deductionRecord'));

export default {
  path: 'salaryManage',
  element: <Outlet />,
  options: {
    title: '发薪管理',
    permissionKey: 'menu-salaryManage',
    key: 'menu-salaryManage',
    icon: <Icon.ContactsOutlined />,
    link: '/salaryManage',
    // allowAccess: true,
  },
  children: [
    {
      path: 'accountOverview',
      element: <Outlet />,
      options: {
        title: '账户总览',
        key: 'menu-salaryManage-accountOverview',
        icon: <Icon.FileOutlined />,
        link: '/salaryManage/accountOverview',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="账户总览" allowAccess permissionKey="menu-salaryManage-accountOverview" component={<AccountOverview />} />,
        },
      ],
    }, {
      path: 'salarySetting',
      element: <Outlet />,
      options: {
        title: '发放设置',
        key: 'menu-salaryManage-salarySetting',
        icon: <Icon.FileOutlined />,
        link: '/salaryManage/salarySetting',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="发放设置" allowAccess permissionKey="menu-salaryManage-salarySetting" component={<SalarySetting />} />,
        },
      ],
    },
    {
      path: 'deliveryManage',
      element: <Outlet />,
      options: {
        title: '代发管理',
        key: 'menu-salaryManage-deliveryManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/salaryManage/deliveryManage',
        allowAccess: true,
      },
      children: [
        {
          path: 'salayList',
          element: <Outlet />,
          options: {
            title: '发放名单',
            key: 'menu-salaryManage-deliveryManage-salayList',
            icon: <Icon.FileOutlined />,
            link: '/salaryManage/deliveryManage/salayList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="发放名单" allowAccess permissionKey="menu-salaryManage-deliveryManage-salayList" component={<SalayLists />} />,
            },
          ],
        }, {
          path: 'salaryReview',
          element: <Outlet />,
          options: {
            title: '发放审核',
            key: 'menu-salaryManage-deliveryManage-salaryReview',
            icon: <Icon.FileOutlined />,
            link: '/salaryManage/deliveryManage/salaryReview',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="发放审核" allowAccess permissionKey="menu-salaryManage-deliveryManage-salaryReview" component={<SalaryReview />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="发放批次详情" allowAccess permissionKey="menu-salaryManage-deliveryManage-salaryRecords" component={<SalaryDetail />} />,
            },
          ],
        }, {
          path: 'salaryRecords',
          element: <Outlet />,
          options: {
            title: '发放记录',
            key: 'menu-salaryManage-deliveryManage-salaryRecords',
            icon: <Icon.FileOutlined />,
            link: '/salaryManage/deliveryManage/salaryRecords',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="发放记录" allowAccess permissionKey="menu-salaryManage-deliveryManage-salaryRecords" component={<SalaryRecords />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="发放批次详情" allowAccess permissionKey="menu-salaryManage-deliveryManage-salaryRecords" component={<SalaryDetail />} />,
            },
          ],
        },
      ],
    }, {
      path: 'deductionRecord',
      element: <Outlet />,
      options: {
        title: '扣回记录',
        key: 'menu-salaryManage-deductionRecord',
        icon: <Icon.FileOutlined />,
        link: '/salaryManage/deductionRecord',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="扣回记录" allowAccess permissionKey="menu-salaryManage-deductionRecord" component={<DeductionRecord />} />,
        },
      ],
    },
  ],
};
