import React, { useState } from 'react';
import {
  Button, message, Modal, Spin, Table, Upload,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookies from 'js-cookie';
import authStore from '@stores/auth';
import { storageKeys } from '@/constants';

const generateColumns = () => [
  {
    title: '错误行',
    dataIndex: 'row',
    key: 'row',
    width: 100,
    render: (text) => (text ? `第${text}行` : null),
  },
  {
    title: '错误信息',
    dataIndex: 'errorMsg',
    key: 'errorMsg',
  }];
const ImportButton = ({
  permissionKey, downloadTempUrl, downloadTempBtnName, httpType, importUrl, importBtnName, successCallback, visibleUpload,
}) => {
  const columns = generateColumns();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errorData, setErrorData] = useState([]);

  // 导出数据请求
  const importDataRequest = async ({ file }) => {
    try {
      let httpUrl;
      switch (httpType) {
        case 'awardHttp':
          httpUrl = process.env.REACT_APP_API_ROUTEOPSHUB + importUrl;
          break;
        case 'contractHttp':
          httpUrl = process.env.REACT_APP_API_ROUTEPOINT + importUrl;
          break;
        case 'attendanceHttp':
          httpUrl = process.env.REACT_APP_API_ROUTEATTEND + importUrl;
          break;
        case 'accountHttp':
          httpUrl = process.env.REACT_APP_API_ROUTELOGIN + importUrl;
          break;
        default:
          httpUrl = process.env.REACT_APP_API_ENDPOINT + importUrl;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      await axios({
        method: 'post',
        // url: process.env.REACT_APP_API_ENDPOINT + importUrl,
        url: httpUrl,
        data: formData,
        timeout: 180000,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Device': 'pc',
          'X-User-Token': Cookies.get(storageKeys.token) || '',
          'X-Tenant-Code': Cookies.get(storageKeys.tenantCode) || '',
        },
      }).then((res) => {
        if (res.data) {
          if (res.data.code === 0) {
            message.success('导入成功');
            successCallback();
          } else if (res.data.data) { // 导入失败并且有错误信息返回，则需要显示在界面上
            setErrorData(res.data.data);
            setVisible(true);
          } else {
            message.error(res.data.msg);
          }
        } else {
          message.error('上传失败');
        }
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  if (permissionKey !== '' && authStore.permissions.indexOf(permissionKey) === -1) {
    return '';
  }
  return (
    <Spin spinning={isLoading}>
      <a href={downloadTempUrl}><Button icon={<DownloadOutlined />} style={{ marginRight: 20 }}>{downloadTempBtnName}</Button></a>
      {
        visibleUpload ? (
          <Upload name="file" accept=".xlsx,.xls" showUploadList={false} customRequest={importDataRequest}>
            <Button type="primary" ghost icon={<UploadOutlined />}>{importBtnName}</Button>
          </Upload>
        ) : null
      }
      <Modal
        title="导入错误信息"
        visible={visible}
        width={900}
        onCancel={onClose}
        footer={null}
        maskClosable={false}
      >
        <Table
          bordered
          size="small"
          scroll={{
            x: 800,
            y: 400,
          }}
          pagination={false}
          rowKey={(record) => record.row}
          columns={columns}
          dataSource={errorData}
        />
      </Modal>
    </Spin>
  );
};

ImportButton.propTypes = {
  permissionKey: PropTypes.string, // 按钮所需权限
  downloadTempUrl: PropTypes.string.isRequired, // 导入模板
  downloadTempBtnName: PropTypes.string, // 导入模板按钮名称
  httpType: PropTypes.string, // 请求类型，
  importUrl: PropTypes.string.isRequired, // 导入请求的URL
  importBtnName: PropTypes.string, // 导入按钮名称
  successCallback: PropTypes.func, // 导入成功的回调函数
  visibleUpload: PropTypes.bool, // 是否显示导入按钮
};
ImportButton.defaultProps = {
  permissionKey: '',
  downloadTempBtnName: '导入模板下载',
  httpType: 'backendHttp',
  importBtnName: '导入',
  visibleUpload: true,
  successCallback() {
    console.info('导入成功');
  },
};

export default ImportButton;
