const month = () => {
  const result = [];
  for (let i = 1; i <= 31; i += 1) {
    result.push({ label: `${i}号`, value: i });
  }
  return result;
};

const weekDays = () => [
  { label: '周一', value: 2, checked: true },
  { label: '周二', value: 3, checked: true },
  { label: '周三', value: 4, checked: true },
  { label: '周四', value: 5, checked: true },
  { label: '周五', value: 6, checked: true },
  { label: '周六', value: 7 },
  { label: '周日', value: 1 },
];

// 流量类型
const httpTypes = {
  BACKENDHTTP: 'backendHttp', // 招聘端服务器接口
  ACCOUNTHTTP: 'accountHttp', // 账号端服务器接口
  CONTRACTHTTP: 'contractHttp', // 合同端服务器接口
  ATTENDANCEHTTP: 'attendanceHttp', // 考勤打卡端服务器接口
  AWARDHTTP: 'awardHttp', // 抽奖活动端服务器接口

};

export default {
  isDisable: [
    { label: '是', value: true },
    { label: '否', value: false, checked: true },
  ],
  isDisableType: [
    { label: '是', value: false },
    { label: '否', value: true },
  ],
  messageType: [
    { label: '公告', value: 'ANNOUNCEMENT' },
    { label: '通知', value: 'ADMIN' },
  ],
  messageTarget: [
    { label: '内部员工', value: 'INNER' },
    { label: '会员', value: 'MEMBER' },
    { label: '所有', value: 'ALL' },
    { label: '指定会员', value: 'DEFINE_MEMBER' },
  ],
  newsType: [
    { label: '进厂攻略', value: 'NEWS_TYPE_ENTRY_FACTORY_METHOD' },
    { label: '公司动态', value: 'NEWS_TYPE_COMPANY_TRENDS' },
    { label: '工厂百科', value: 'NEWS_TYPE_FACTORY_WIKI' },
  ],
  accountType: [
    { label: '内部账号', value: 'RECRUIT' },
    { label: '供应商账号', value: 'RECRUIT_SUPPLIER' },
  ],
  isPublish: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
  genderLimit: [
    { label: '不限', value: false },
    { label: '限制', value: true },
  ],
  userStatus: [
    { label: '在职', value: 'ON_LINE', checked: true },
    { label: '离职', value: 'RESIGN' },
  ],
  statusResult: [
    { label: '待处理', value: 'PREPARING' },
    { label: '处理中', value: 'PROCESSING' },
    { label: '已结案', value: 'END' },
  ],
  // 拜访客户等级
  visitLevel: [
    { label: 'A', value: 'LEVEL_A' },
    { label: 'B', value: 'LEVEL_B' },
    { label: 'C', value: 'LEVEL_C' },
  ],
  visitType: [
    { label: '企业', value: 'COMPANY' },
    { label: '供应商', value: 'SUPPLIER' },
  ],
  visitStatus: [
    { label: '待拜访', value: 'PENDING' },
    { label: '已拜访', value: 'FINISHED' },
  ],
  visitResults: [
    { label: '战略合作', value: 'RESULT_STRATEGY' },
    { label: '深度合作', value: 'RESULT_DEPTH' },
    { label: '意向合作', value: 'RESULT_INTENTION' },
    { label: '考虑中', value: 'RESULT_CONSIDER' },
  ],
  typeResult: [
    { label: '薪资问题', value: 'SALARY' },
    { label: '借支问题', value: 'BORROW' },
    { label: '驻厂问题', value: 'RESIDENTIAL' },
    { label: '投诉问题', value: 'COMPLAINT' },
    { label: '宿舍问题', value: 'DORMITORY' },
  ],
  isEnd: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],

  // 订单分类
  orderType: [
    { label: '主推企业', value: 'MAIN_COMPANY' },
    { label: '常规企业', value: 'HOT' },
    { label: '正式工专区', value: 'FORMAL' },
    { label: '奖励专区', value: 'AWARD' },
    { label: '二手单专区', value: 'SUPPLIER' },
  ],
  /* 企业管理 */
  companyScale: [
    { label: '0-500人', value: 'SCALE_FIRST' },
    { label: '501-1000人', value: 'SCALE_SECOND' },
    { label: '1001-3000人', value: 'SCALE_THIRD' },
    { label: '3001-5000人', value: 'SCALE_FOURTH' },
    { label: '5000人以上', value: 'SCALE_FIFTH' },
  ],
  companyType: [
    { label: 'A', value: 'TYPE_A' },
    { label: 'B', value: 'TYPE_B' },
    { label: 'C', value: 'TYPE_C' },
  ],
  companyIndustry: [
    { label: '制造业', value: 'INDUSTRY_MANUFACTURE' },
    { label: '食品业', value: 'INDUSTRY_FOOD' },
    { label: '服务业', value: 'INDUSTRY_SERVE' },
  ],
  companyShift: [
    { label: '长白班', value: 'SHIFT_CATEGORY_LONG' },
    { label: '两班倒', value: 'SHIFT_CATEGORY_TWO' },
    { label: '三班倒', value: 'SHIFT_CATEGORY_THREE' },
  ],
  sitStand: [
    { label: '站班', value: 'STAND' },
    { label: '坐班', value: 'SIT_DOWN' },
    { label: '都有', value: 'ALL_HAVE' },
  ],
  dress: [
    { label: '普通工衣', value: 'DRESS_ORDINARY' },
    { label: '无尘服', value: 'DRESS_DUST_FREE' },
    { label: '穿自己衣服', value: 'DRESS_OWN' },
  ],
  companyLine: [
    { label: '流水线', value: 'LINE_WATER' },
    { label: '非流水线', value: 'LINE_NOT_WATER' },
    { label: '都有', value: 'ALL_HAVE' },
  ],
  microscope: [
    { label: '看', value: 'MICROSCOPE_SEE' },
    { label: '不看', value: 'MICROSCOPE_NOT_SEE' },
    { label: '都有', value: 'ALL_HAVE' },
  ],
  dormitory: [
    { label: '住宿免费', value: 'DORMITORY_FREE' },
    { label: '住宿扣费', value: 'DORMITORY_NOT_FREE' },
    { label: '无住宿', value: 'NOT_DORMITORY' },
  ],
  companyFood: [
    { label: '免费工作餐', value: 'FOOD_FREE' },
    { label: '三餐包吃', value: 'FOOD_THREE_INCLUDE' },
    { label: '刷卡吃饭', value: 'FOOD_SWIPE' },
    { label: '自费吃饭', value: 'FOOD_OWN' },
  ],
  companyPhone: [
    { label: '可以带', value: 'PHONE_CARRY' },
    { label: '不能带', value: 'PHONE_NOT_CARRY' },
  ],
  companyIdCard: [
    { label: '必须有磁有效', value: 'ID_CARD_MAGNETIC' },
    { label: '临时身份证可去', value: 'ID_CARD_TEMP' },
    { label: '无身份证可去', value: 'ID_CARD_NOT' },
  ],
  companyEnglish: [
    { label: '不会可去', value: 'ENGLISH_NOT_MUST' },
    { label: '必须要会', value: 'ENGLISH_MUST' },
  ],
  returnFactory: [
    { label: '不要返厂', value: 'RETURN_FACTORY_NOT' },
    { label: '返厂可去', value: 'RETURN_FACTORY' },
    { label: '有条件返厂可去', value: 'RETURN_FACTORY_CONDITION' },
  ],
  tattooSmoke: [
    { label: '严查', value: 'TATTOO_SMOKE_CHECK' },
    { label: '不查', value: 'TATTOO_SMOKE_NOT_CHECK' },
    { label: '不可外露', value: 'TATTOO_SMOKE_NOT_EXPOSED' },
  ],
  companyNationality: [
    { label: '不限', value: 'NATIONALITY_UNLIMITED' },
    { label: '只要汉族', value: 'NATIONALITY_ONLY_HAN' },
    { label: '除四大名族可去', value: 'NATIONALITY_EXCLUDE_FOUR' },
  ],
  background: [
    { label: '案底能去', value: 'BACKGROUND_UNLIMITED' },
    { label: '案底不能去', value: 'BACKGROUND_LIMIT' },
  ],
  studentProve: [
    { label: '不查证明', value: 'STUDENT_PROVE_NOT_CHECK' },
    { label: '严查证明', value: 'STUDENT_PROVE_SEVERE_CHECK' },
  ],
  sex: [
    { label: '不限', value: 'UNLIMIT' },
    { label: '限制比例', value: 'LIMIT' },
  ],
  salary: [
    { label: '日借支', value: 'DAILY' },
    { label: '周借支', value: 'WEEKLY' },
    { label: '月借支', value: 'MONTHLY' },
    { label: '无借支', value: 'NONE' },
  ],
  waterElectricity: [
    { label: '不扣费', value: 'NODEDUCTION' },
    { label: '超额扣费', value: 'EXCESSDEDUCTION' },
    { label: '房间均摊', value: 'ROOMSHARING' },
    { label: '全员均摊', value: 'WHOLESHARING' },
    { label: '定额均摊', value: 'QUOTASHARING' },
  ],
  profession: [
    { label: '正式工', value: 'FORMAL_WORKER' },
    { label: '派遣工-小时工', value: 'DISPATCH_HOURLY_WORKER' },
    { label: '派遣工-同工同酬', value: 'DISPATCH_EQUAL_PAY' },
  ],
  ifShelf: [
    { label: '是', value: 'TRUE' },
    { label: '否', value: 'FALSE' },
  ],
  isShelf: [
    { label: '上架', value: 'TRUE' },
    { label: '下架', value: 'FALSE' },
  ],
  ifPulish: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
  fissionStatus: [
    { label: '进行中', value: 'PROCESSING' },
    { label: '暂停', value: 'STOP' },
  ],
  cashStatus: [
    { label: '已兑奖', value: 'CASHED' },
    { label: '待兑奖', value: 'CASHING' },
  ],
  giftType: [
    { label: '床上用品', value: 'BEDDING' },
    { label: '洗漱套装', value: 'WASH' },
    { label: '节日红包', value: 'HOLIDAY' },
    { label: '上车红包', value: 'CAR' },
    { label: '开工红包', value: 'START_WORK' },
    { label: '生日红包', value: 'BIRTHDAY' },
    { label: '粽子', value: 'ZONG_ZI' },
    { label: '月饼', value: 'MOON_CAKE' },
    { label: '其他', value: 'OTHER' },
  ],
  giftStatus: [
    { label: '已发放', value: 'ISSUED' },
    { label: '不符合', value: 'NO_ACCORD' },
  ],
  secondType: [
    { label: '归属招聘员二维码', value: 'EXPAND_INVITATION_RECRUITER' },
    { label: '预约推荐报名二维码', value: 'EXPAND_INVITATION_PREPARE_SIGNUP' },
  ],
  adjunctType: [
    { label: '早安', value: 'EXPAND_INVITATION_MORNING' },
    { label: '晚安', value: 'EXPAND_INVITATION_NIGHT' },
    { label: '节日节气', value: 'EXPAND_INVITATION_FESTIVAL' },
    { label: '正能量', value: 'EXPAND_INVITATION_JUST' },
    { label: '预约活动', value: 'EXPAND_INVITATION_PREPARE' },
    { label: '其他', value: 'EXPAND_INVITATION_OTHER' },
  ],
  cardType: [
    { label: '节日贺卡', value: 'FESTIVAL' },
    { label: '生日贺卡', value: 'BIRTHDAY' },
    { label: '入职贺卡', value: 'ENTRY' },
  ],
  post: [
    { label: '普工', value: 'GENERAL_WORKER' },
    { label: '技工', value: 'TECH_WORKER' },
    { label: '工程师', value: 'ENGINEER_WORKER' },
  ],
  // 提成类别
  empRewardType: [
    { label: '短线', value: 'STUB' },
    { label: '长线', value: 'LONG_LINE' },
  ],
  // 工价模式
  settlementMode: [
    { label: '纯工价', value: 'WAGE' },
    { label: '工价+返费', value: 'REBATE_WAGE' },
    { label: '工价+差价', value: 'SPREAD_WAGE' },
    { label: '满自然月', value: 'MONTH' },
    { label: '发薪日在职', value: 'PAYDAY' },
    { label: '满工期', value: 'DURATION' },
    { label: '其他', value: 'OTHER' },
  ],
  settleModelType: [
    { label: '短线', value: 'STUB' },
    { label: '长线', value: 'LONG_LINE' },
    { label: '打包价', value: 'PACKAGE_PRICE' },
    { label: '代理费', value: 'AGENT' },
    { label: '其他', value: 'OTHER' },
  ],
  signUp: [
    { label: '自主报名', value: 'SELF' },
    { label: '门店录入', value: 'RECRUITER' },
    { label: '供应商', value: 'SUPPLIER' },
    { label: '会员推荐', value: 'MEMBER_RECOMMEND' },
  ],
  expirationType: [
    { label: '15天内到期', value: 'FIFTEEN_WITHIN' },
    { label: '30天内到期', value: 'THIRTY_WITHIN' },
    { label: '31天以上到期', value: 'THIRTY_ONE_BEYOND' },
    { label: '已失效合同', value: 'EXPIRED' },
  ],
  advanceStatus: [
    { label: '审核中', value: 'PENDING' },
    { label: '拒绝', value: 'FAIL' },
    { label: '通过', value: 'PASS' },
    { label: '撤回', value: 'CANCEL' },
    { label: '废弃', value: 'INVALID' },
  ],
  advanceNode: [
    { label: '驻厂', value: 0 },
    { label: '财务', value: 1 },
    { label: '出纳', value: 2 },
  ],
  advanceStatus1: [
    { label: '拒绝', value: false },
    { label: '通过', value: true },
  ],
  shiftType: [
    { label: '白班', value: 'DAYTIME' },
    { label: '夜班', value: 'NIGHT' },
  ],
  workCycleOptions: [
    { label: '当月1日-月底31日', value: { start: '1', end: '31', explain: '当月1日-月底31日' } },
    { label: '当月2日-次月1日', value: { start: '2', end: '1', explain: '当月2日-次月1日' } },
    { label: '当月3日-次月2日', value: { start: '3', end: '2', explain: '当月3日-次月2日' } },
    { label: '当月4日-次月3日', value: { start: '4', end: '3', explain: '当月4日-次月3日' } },
    { label: '当月5日-次月4日', value: { start: '5', end: '4', explain: '当月5日-次月4日' } },
    { label: '当月6日-次月5日', value: { start: '6', end: '5', explain: '当月6日-次月5日' } },
    { label: '当月7日-次月6日', value: { start: '7', end: '6', explain: '当月7日-次月6日' } },
    { label: '当月8日-次月7日', value: { start: '8', end: '7', explain: '当月8日-次月7日' } },
    { label: '当月9日-次月8日', value: { start: '9', end: '8', explain: '当月9日-次月8日' } },
    { label: '当月10日-次月9日', value: { start: '10', end: '9', explain: '当月10日-次月9日' } },
    { label: '当月11日-次月10日', value: { start: '11', end: '10', explain: '当月11日-次月10日' } },
    { label: '当月12日-次月11日', value: { start: '12', end: '11', explain: '当月12日-次月11日' } },
    { label: '当月13日-次月12日', value: { start: '13', end: '12', explain: '当月13日-次月12日' } },
    { label: '当月14日-次月13日', value: { start: '14', end: '13', explain: '当月14日-次月13日' } },
    { label: '当月15日-次月14日', value: { start: '15', end: '14', explain: '当月15日-次月14日' } },
    { label: '当月16日-次月15日', value: { start: '16', end: '15', explain: '当月16日-次月15日' } },
    { label: '当月17日-次月16日', value: { start: '17', end: '16', explain: '当月17日-次月16日' } },
    { label: '当月18日-次月17日', value: { start: '18', end: '17`', explain: '当月18日-次月17日' } },
    { label: '当月19日-次月18日', value: { start: '19', end: '18', explain: '当月19日-次月18日' } },
    { label: '当月20日-次月19日', value: { start: '20', end: '19', explain: '当月20日-次月19日' } },
    { label: '当月21日-次月20日', value: { start: '21', end: '20', explain: '当月21日-次月20日' } },
    { label: '当月22日-次月21日', value: { start: '22', end: '21', explain: '当月22日-次月21日' } },
    { label: '当月23日-次月22日', value: { start: '23', end: '22', explain: '当月23日-次月22日' } },
    { label: '当月24日-次月23日', value: { start: '24', end: '23', explain: '当月24日-次月23日' } },
    { label: '当月25日-次月24日', value: { start: '25', end: '24', explain: '当月25日-次月24日' } },
    { label: '当月26日-次月25日', value: { start: '26', end: '25', explain: '当月26日-次月25日' } },
    { label: '当月27日-次月26日', value: { start: '27', end: '26', explain: '当月27日-次月26日' } },
    { label: '当月28日-次月27日', value: { start: '28', end: '27', explain: '当月28日-次月27日' } },
  ],
  shiftSwitching: [
    { label: '安全切换', value: 0, desc: '次日考勤距当日上班卡24小时内不可切换其他班次' },
    { label: '自由切换', value: 1, desc: '当日考勤完成后，可自由切换其他班次' },
  ],
  deductionType: [
    { label: '按休息时段扣减', value: 'REST' },
    { label: '按固定工时扣减', value: 'WORK' },
  ],
  clockType: [
    { label: '打卡方式相同', value: 0 },
    { label: '打卡方式不同', value: 1 },
  ],
  clockInType: [
    { label: '定位打卡', value: 0, desc: '员工需要在设置的厂区范围内打卡' },
    { label: '拍照定位打卡', value: 1, desc: '员工需要在设置的厂区范围内并且上传照片打卡' },
    { label: '人脸识别定位打卡', value: 1, desc: '员工需要在设置的厂区范围内并且人脸识别打卡' },
  ],
  workHourType: [
    { label: '打卡工时', value: 0 },
    { label: '有效工时', value: 1 },
  ],
  wagesType: [
    { label: '小时工价', value: 'HOUR' },
    { label: '固定工价', value: 'FIX' },
  ],
  enterType: [
    { label: '手动录入', value: 'INPUT' },
    { label: '名单导入', value: 'IMPORT' },
    { label: 'EXCEL表格导入', value: 'EXCEL_IMPORT' },
    { label: '扫码录入', value: 'SCAN' },
  ],
  salaryDeposit: [
    { label: '押天数', value: true },
    { label: '不押薪', value: false },
  ],
  advance_amount: [
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
  ],
  banckWay: [
    { label: '银行卡', value: 'PAYMENT_BANK' },
    { label: '无卡发薪', value: 'PAYMENT_NO_CARD' },
    { label: '其他', value: 'PAYMENT_OTHER' },
  ],
  repayType: [
    { label: '工资扣除', value: 'REPAYMENT_SALARY' },
  ],
  repayStatus: [
    { label: '待处理', value: 'PENDING' },
    { label: '已还款', value: 'END' },
  ],
  billStatus: [
    { label: '待确认', value: 'WAIT_CONFIRM' },
    { label: '已驳回', value: 'REFUSE' },
    { label: '待打款', value: 'WAIT_TRANSFER' },
    { label: '已打款', value: 'TRANSFER' },
  ],
  suppliBillStatus: [
    { label: '拒绝', value: 'REFUSE' },
    { label: '通过', value: 'WAIT_TRANSFER' },
  ],
  months: [
    { label: '1月', value: '1' },
    { label: '2月', value: '2' },
    { label: '3月', value: '3' },
    { label: '4月', value: '4' },
    { label: '5月', value: '5' },
    { label: '6月', value: '6' },
    { label: '7月', value: '7' },
    { label: '8月', value: '8' },
    { label: '9月', value: '9' },
    { label: '10月', value: '10' },
    { label: '11月', value: '11' },
    { label: '12月', value: '12' },
  ],
  sendStatus: [
    { label: '已发送', value: true },
    { label: '未发送', value: false },
  ],
  // 联系人关系
  relationship: [
    { label: '朋友', value: 'FRIEND' },
    { label: '父母', value: 'PARENT' },
    { label: '儿女', value: 'CHILDREN' },
    { label: '兄弟姐妹', value: 'BROTHER_OR_SISTERS' },
    { label: '配偶', value: 'SPOUSE' },
    { label: '亲戚', value: 'RELATIVE' },
  ],
  // 费用项目
  costItem: [
    { label: '房租', value: 'DORM_COST_RENT' },
    { label: '水费', value: 'DORM_COST_WATER' },
    { label: '电费', value: 'DORM_COST_POWER' },
    { label: '物业费', value: 'DORM_COST_PROPERTY' },
    { label: '管理费', value: 'DORM_COST_CONTROL' },
    { label: '维修费', value: 'DORM_COST_FIX' },
  ],
  // 预约名单状态值
  preSignUpStatus: [
    { label: '待处理', value: 'PENDING' },
    { label: '无效', value: 'UN_EFFECT' },
    { label: '转报名', value: 'TO_SIGN' },
  ],
  // 最新状态值
  newStatus: [
    { label: '报名-待处理', value: 'SIGN_UP_PENDING', operator: false },
    { label: '报名-无意向', value: 'SIGN_UP_NO_INTENTION' },
    { label: '报名-已到面', value: 'SIGN_UP_INTENTION', status: false },
    { label: '面试-待处理', value: 'INTERVIEW_PENDING', operator: false },
    { label: '面试-通过', value: 'INTERVIEW_PASS', status: false },
    { label: '面试-未去面试', value: 'INTERVIEW_NO_ARRIVE' },
    { label: '面试-未通过', value: 'INTERVIEW_FAIL' },
    { label: '入职-待处理', value: 'ON_BOARDING_PENDING', operator: false },
    { label: '入职-未报到', value: 'ON_BOARDING_FAIL' },
    { label: '入职-已入职', value: 'ON_BOARDING_PASS', status: false },
    { label: '在职', value: 'JOB_ON', operator: false },
    { label: '离职', value: 'JOB_RESIGN' },
  ],
  // 报名状态值
  signUpStatus: [
    { label: '待处理', value: 'SIGN_UP_PENDING' },
    { label: '无意向', value: 'SIGN_UP_NO_INTENTION' },
    { label: '已到面', value: 'SIGN_UP_INTENTION' },
  ],
  // 面试状态值
  interViewStatus: [
    { label: '待处理', value: 'INTERVIEW_PENDING' },
    { label: '面试通过', value: 'INTERVIEW_PASS' },
    { label: '未去面试', value: 'INTERVIEW_NO_ARRIVE' },
    { label: '面试未通过', value: 'INTERVIEW_FAIL' },
  ],
  // 入职状态值
  boardingStatus: [
    { label: '待处理', value: 'ON_BOARDING_PENDING' },
    { label: '未报到', value: 'ON_BOARDING_FAIL' },
    { label: '已入职', value: 'ON_BOARDING_PASS' },
  ],
  // 离职状态值
  jobStatus: [
    { label: '在职', value: 'JOB_ON' },
    { label: '离职', value: 'JOB_RESIGN' },
  ],
  // 是否在职
  jobOnStatus: [
    { label: '在职', value: true },
    { label: '离职', value: false },
  ],
  // 是否在职
  showStatus: [
    { label: '展示', value: true },
    { label: '隐藏', value: false },
  ],
  // 印章类型
  sealType: [
    { label: '企业印章', value: 'OFFICIAL' },
    { label: '企业法人印章', value: 'LEGAL_PERSON' },
  ],
  // 离职状态值
  salarySign: [
    { label: '已签收', value: true },
    { label: '未签收', value: false },
  ],
  memberLiveType: [
    { label: '公司宿舍', value: 'COMPANY_DORM' },
    { label: '个人独租', value: 'RENT_ALONE' },
    { label: '朋友合租', value: 'RENT_TOGETHER' },
  ],
  // 车辆类型
  carType: [
    { label: '小型客车', value: 'CAR_TYPE_SMALL' },
    { label: '中型客车', value: 'CAR_TYPE_MIDDLE' },
    { label: '大型客车', value: 'CAR_TYPE_LARGE' },
  ],
  // 车辆属性
  carProperty: [
    { label: '公司自有', value: 'CAR_PROPERTY_SELF' },
    { label: '外部租赁', value: 'CAR_PROPERTY_RENT' },
  ],

  // 费用类型
  costType: [
    { label: '加油费', value: 'GASOLINE' },
    { label: '加尿素费用', value: 'UREA' },
    { label: '修车费用', value: 'FIX' },
    { label: '保养费用', value: 'MAINTAIN' },
    { label: '洗车费用', value: 'WASHING' },
    { label: '路桥费用', value: 'ROAD_TOLL' },
    { label: '停车费用', value: 'PARK' },
    { label: '其他费用', value: 'OTHER' },
  ],
  // 用车状态
  carStatus: [
    { label: '待发车', value: 'CAR_ORDER_WAIT' },
    { label: '输送中', value: 'CAR_ORDER_PENDING' },
    { label: '已完成', value: 'CAR_ORDER_COMPLETED' },
  ],
  // 用车状态
  carOrderStatus: [
    { label: '输送中', value: 'CAR_ORDER_PENDING' },
    { label: '已完成', value: 'CAR_ORDER_COMPLETED' },
  ],
  // 是否
  ifStatus: [
    { label: '是', value: 'YES' },
    { label: '否', value: 'NO' },
  ],
  // 是否
  ifHasOrder: [
    { label: '是', value: 'true' },
    { label: '否', value: 'false' },
  ],
  // 用车类型
  useType: [
    { label: '会员用车', value: 'CAR_ORDER_FOR_MEMBER' },
    { label: '办公用车', value: 'CAR_ORDER_FOR_OFFICE' },
    { label: '私人用车', value: 'CAR_ORDER_FOR_PRIVATE' },
  ],
  // 宿舍类型
  dormitoryType: [
    { label: '标准宿舍', value: 'DORM_COMMON' },
  ],
  floorType: [
    { label: '男生宿舍', value: 'DORM_MALE' },
    { label: '女生宿舍', value: 'DORM_FEMALE' },
  ],
  collectionType: [
    { label: '对公', value: 'PUBLIC' },
    { label: '对私', value: 'PRIVATE' },
  ],
  assetType: [
    { label: '正常', value: 'NORMAL' },
    { label: '损坏', value: 'DAMAGE' },
    { label: '报修', value: 'FIX' },
    { label: '报废', value: 'SCRAP' },
    { label: '丢失', value: 'LOSE' },
  ],
  unitType: [
    { label: '个', value: '个' },
    { label: '台', value: '台' },
    { label: '套', value: '套' },
    { label: '组', value: '组' },
  ],
  abilityType: [
    { label: '常规住宿', value: 'DORM_ROUTINE' },
    { label: '临时住宿', value: 'DORM_TEMPORARY' },
    { label: '管理员宿舍', value: 'DORM_ADMIN' },
    { label: '租用派遣', value: 'DORM_HIRE_DISPATCH' },
    { label: '租用其他', value: 'DORM_HIRE_OTHER' },
    { label: '储物室', value: 'DORM_STORE' },
    { label: '维修中', value: 'DORM_FIXING' },
  ],
  liveType: [
    { label: '常规住宿', value: 'DORM_ROUTINE' },
    { label: '临时住宿', value: 'DORM_TEMPORARY' },
  ],
  leaveType: [
    { label: '临时住宿退宿', value: 'DORM_LIVE_OUT_TEMPORARY' },
    { label: '离职退宿', value: 'DORM_LIVE_OUT_RESIGN' },
    { label: '违纪取消住宿', value: 'DORM_LIVE_OUT_VIOLATE' },
    { label: '个人外租退宿', value: 'DORM_LIVE_OUT_PERSONAL' },
    { label: '调迁住宿', value: 'DORM_LIVE_OUT_TRANSFER' },
  ],
  liveStatus: [
    { label: '待处理', value: 'DORM_LIVE_PENDING' },
    { label: '退宿', value: 'DORM_LIVE_OUT' },
    { label: '在宿', value: 'DORM_LIVE_IN' },
  ],
  disciplinType: [
    { label: '违规使用大功率电器', value: 'DORM_DISCIPLINE_HIGH_ELECTRICAL' },
    { label: '私拉乱接电线', value: 'DORM_DISCIPLINE_PRIVATE_ELECTRICAL' },
    { label: '恶意损坏宿舍物品', value: 'DORM_DISCIPLINE_MALICIOUS_DAMAGE' },
    { label: '宿舍打架斗殴', value: 'DORM_DISCIPLINE_FIGHT' },
    { label: '私藏管制刀具', value: 'DORM_DISCIPLINE_PRIVATE_KNIVES' },
    { label: '私自闯入异性宿舍', value: 'DORM_DISCIPLINE_OPPOSITE_SEX' },
    { label: '违规带外人住宿', value: 'DORM_DISCIPLINE_TAKE_OUTSIDERS_TO_STAY' },
    { label: '其他', value: 'DORM_DISCIPLINE_OTHER' },
  ],
  disciplinResult: [
    { label: '警告', value: 'DORM_DISCIPLINE_RESULT_WARN' },
    { label: '二次警告', value: 'DORM_DISCIPLINE_RESULT_SECONDARY_WARN' },
    { label: '违纪取消住宿', value: 'DORM_DISCIPLINE_RESULT_OUT' },
  ],
  checkStatus: [
    { label: '待点检', value: 'DORM_CHECK_PENDING' },
    { label: '已点检', value: 'DORM_CHECK_OK' },
  ],
  hygStatus: [
    { label: '合格', value: 'QUALIFIED' },
    { label: '未合格', value: 'UNQUALIFIED' },
  ],
  assetStatus: [
    { label: '正常', value: 'DORM_ASSET_NORMAL' },
    { label: '损坏', value: 'DORM_ASSET_DAMAGE' },
    { label: '报修', value: 'DORM_ASSET_FIX' },
    { label: '报废', value: 'DORM_ASSET_SCRAP' },
    { label: '丢失', value: 'DORM_ASSET_LOSE' },
  ],
  fixStatus: [
    { label: '待处理', value: 'DORM_REPAIR_PENDING' },
    { label: '已处理', value: 'DORM_REPAIR_END' },
  ],
  fixType: [
    { label: '灯管类', value: 'DORM_REPAIR_LAMP' },
    { label: '门锁类', value: 'DORM_REPAIR_DOOR' },
    { label: '床类', value: 'DORM_REPAIR_BED' },
    { label: '管道堵塞', value: 'DORM_REPAIR_PIPE_BLOCKAGE' },
    { label: '马桶', value: 'DORM_REPAIR_CLOSESTOOL' },
    { label: '水龙头', value: 'DORM_REPAIR_TAP' },
    { label: '沐浴喷头', value: 'DORM_REPAIR_SHOWER_NOZZLE' },
    { label: '空调器', value: 'DORM_REPAIR_AIRCONDITION' },
    { label: '热水器', value: 'DORM_REPAIR_HEATER' },
    { label: '插座线路', value: 'DORM_REPAIR_SOCKET_LINE' },
    { label: '其他', value: 'DORM_REPAIR_OTHER' },
  ],
  wayToGo: [
    { label: '自行到厂', value: 'FACTORY' },
    { label: '门店集合', value: 'STORE' },
  ],
  sexual: [
    { label: '男', value: 'MALE' },
    { label: '女', value: 'FEMALE' },
  ],
  reVisitResult: [
    { label: '待回访', value: 'PREPARING' },
    { label: '有意愿', value: 'HAVE_WILL' },
    { label: '无意愿', value: 'NO_WILL' },
  ],
  reVisitResults: [
    { label: '有意愿', value: 'HAVE_WILL' },
    { label: '无意愿', value: 'NO_WILL' },
  ],
  visitResult: [
    { label: '待回访', value: 'PREPARING' },
    { label: '通过', value: 'PASS' },
    { label: '不通过', value: 'NO_PASS' },
  ],
  education: [
    { label: '小学', value: 'PRIMARY' },
    { label: '初中', value: 'JUNIOR_HIGH' },
    { label: '高中', value: 'HIGH' },
    { label: '大专', value: 'COLLEGE' },
    { label: '本科', value: 'UNDERGRADUATE' },
    { label: '硕士及以上', value: 'MASTER_ABOVE' },
  ],
  marriage: [
    { label: '未婚', value: 'NONE' },
    { label: '已婚', value: 'ALREADY' },
    { label: '离异', value: 'LEAVE' },
    { label: '丧偶', value: 'LOSE' },
  ],
  sourceTypesByHighSeas: [
    { label: '后台导入', value: 'IMPORT' },
    { label: '游客关注', value: 'VISITOR' },
    { label: '供应商导入', value: 'SUPPLIER' },
    { label: '分享裂变', value: 'SHARE' },
  ],
  sourceTypes: [
    { label: '后台导入', value: 'IMPORT' },
    { label: '游客关注', value: 'VISITOR' },
    { label: '招聘员录入', value: 'INPUT' },
    { label: '供应商导入', value: 'SUPPLIER' },
    { label: '分享裂变', value: 'SHARE' },
  ],
  sourceType: [
    { label: '后台导入', value: 'IMPORT' },
    { label: '供应商导入', value: 'SUPPLIER' },
  ],
  distributeType: [
    { label: '个人流量', value: false },
    { label: '公海流量', value: true },
  ],
  fixedTags: [
    { label: '纹身', value: '1' },
    { label: '烟疤', value: '2' },
    { label: '案底', value: '3' },
    { label: '残疾', value: '4' },
    { label: '烫伤', value: '5' },
    { label: '自残', value: '6' },
  ],
  title: [
    ['basic', '基本信息'],
    ['workEnv', '工作环境'],
    ['salary', '薪资信息'],
    ['hire', '录用条件'],
    ['interview', '面试资料'],
    ['epidemic', '防疫要求'],
    ['contactResidents', '驻厂对接'],
    ['contactBusiness', '商务对接'],
    ['contactFinances', '财务对接'],
  ],
  dataLabel: [
    ['area', '区域'],
    ['companyName', '公司名称'],
    ['companyShortName', '公司简称'],
    ['inDustry', '所属行业'],
    ['introduction', '公司简介'],
    ['scale', '企业规模'],
    ['industry', '所属行业'],
    ['shiftCategory', '班别'],
    ['sitStand', '站坐'],
    ['dress', '着装'],
    ['line', '产线'],
    ['microscope', '显微镜'],
    ['dormitory', '住宿'],
    ['food', '伙食'],
    ['phone', '车间带手机'],
    ['idCard', '身份证'],
    ['english', '英文字母'],
    ['returnFactory', '返厂'],
    ['tattooSmoke', '纹身烟疤'],
    ['nationality', '民族限制'],
    ['background', '案底'],
    ['studentProve', '学生证明'],
    ['height', '身高要求'],
    ['examine', '体检要求'],
    ['remarks', '备注'],
    ['idCardCopy', '身份证复印件'],
    ['graduateCopy', '毕业证复印件'],
    ['photo', '照片'],
    ['itineraryCode', '行程码'],
    ['nucleicAcid', '核酸'],
    ['vaccination', '疫苗接种'],
    ['payDay', '发薪日'],
    ['payCycleStart', '发薪周期起'],
    ['payCycleEnd', '发薪周期止'],
  ],
  dataText: [
    ['SHIFT_CATEGORY_LONG', '长白班'],
    ['SHIFT_CATEGORY_TWO', '两班倒'],
    ['SHIFT_CATEGORY_THREE', '三班倒'],
    ['STAND', '站班'],
    ['SIT_DOWN', '坐班'],
    ['ALL_HAVE', '都有'],
    ['DRESS_ORDINARY', '普通工衣'],
    ['DRESS_OWN', '穿自己衣服'],
    ['DRESS_DUST_FREE', '无尘服'],
    ['LINE_WATER', '流水线'],
    ['LINE_NOT_WATER', '非流水线'],
    ['MICROSCOPE_SEE', '看'],
    ['MICROSCOPE_NOT_SEE', '不看'],
    ['DORMITORY_FREE', '住宿免费'],
    ['DORMITORY_NOT_FREE', '住宿扣费'],
    ['NOT_DORMITORY', '无住宿'],
    ['PHONE_CARRY', '可以带'],
    ['PHONE_NOT_CARRY', '不能带'],
    ['ID_CARD_TEMP', '临时身份证可去'],
    ['ID_CARD_NOT', '无身份证可去'],
    ['ID_CARD_MAGNETIC', '必须有磁有效'],
    ['ENGLISH_NOT_MUST', '不会可去'],
    ['ENGLISH_MUST', '必须要会'],
    ['RETURN_FACTORY_NOT', '不要返厂'],
    ['RETURN_FACTORY', '返厂可去'],
    ['RETURN_FACTORY_CONDITION', '有条件返厂可去'],
    ['TATTOO_SMOKE_CHECK', '严查'],
    ['TATTOO_SMOKE_NOT_CHECK', '不查'],
    ['TATTOO_SMOKE_NOT_EXPOSED', '不可外露'],
    ['NATIONALITY_UNLIMITED', '不限'],
    ['NATIONALITY_ONLY_HAN', '只要汉族'],
    ['NATIONALITY_EXCLUDE_FOUR', '除四大名族可去'],
    ['BACKGROUND_UNLIMITED', '案底能去'],
    ['BACKGROUND_LIMIT', '案底不能去'],
    ['STUDENT_PROVE_NOT_CHECK', '不查证明'],
    ['STUDENT_PROVE_SEVERE_CHECK', '严查证明'],
    ['FOOD_FREE', '免费工作餐'],
    ['FOOD_THREE_INCLUDE', '三餐包吃'],
    ['FOOD_SWIPE', '刷卡吃饭'],
    ['FOOD_OWN', '自费吃饭'],
    ['INDUSTRY_MANUFACTURE', '制造业'],
    ['INDUSTRY_SERVE', '服务业'],
    ['INDUSTRY_FOOD', '食品业'],
    ['SCALE_FIRST', '0-500人'],
    ['SCALE_SECOND', '501-1000人'],
    ['SCALE_THIRD', '1001-3000人'],
    ['SCALE_FOURTH', '3001-5000人'],
    ['SCALE_FIFTH', '5000人以上'],
  ],
  // 合同模板
  contractTags: ['会员姓名', '会员身份证', '会员手机号', '订单日期', '合同生效日期', '合同终止日期', '工价说明', '工价详情', '籍贯', '居住地址', '用工企业', '民族', '出生日期', '年龄', '银行卡号', '开户银行', '银行开户地址', '工种', '岗位', '性别', '备注', '紧急联系人姓名', '紧急联系人手机号'],
  // 合同模板关键字
  contractTemp: [
    {
      label: '第一个关键词',
      value: 'FIRST',
    },
    {
      label: '最后一个关键词',
      value: 'LAST_ONE',
    },
    {
      label: '所有关键词',
      value: 'ALL',
    },
  ],

  // 会员合同状态
  tempStatus: [
    { label: '进行中', value: 'PENDING' },
    { label: '已作废', value: 'CANCEL' },
    { label: '已归档', value: 'FREEZE' },
  ],

  // 合同发送状态
  tempSend: [
    { label: '未发送', value: false },
    { label: '已发送', value: true },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 合同填充状态
  tempFill: [
    { label: '未填充', value: false },
    { label: '已填充', value: true },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 合同审核状态
  tempProve: [
    { label: '未审核', value: 1 },
    { label: '审核通过', value: 2 },
    { label: '审核拒绝', value: 3 },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 合同归档状态
  tempFile: [
    { label: '未归档', value: false },
    { label: '已归档', value: true },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 合同签署状态
  tempSign: [
    { label: '未签署', value: false },
    { label: '已签署', value: true },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 签署结果
  signResult: [
    { label: '签署成功', value: 'SUCCESS' },
    { label: '签署失败', value: 'FAILED' },
    { label: '拒签', value: 'REFUSE' },
    { label: '已作废', value: 'CANCEL' },
  ],

  // 签署类型
  signType: [
    { label: '法人章', value: 'BOSS' },
    { label: '公章', value: 'COMPANY' },
    { label: '用户', value: 'USER' },
  ],

  // 离职状态标签
  resignationTags1: ['辞职', '自离', '正常离职转厂', '工期满转正', '工厂开除', '放弃入职', '转换行业', '自行创业', '月收入低', '新进未报到培训', '签合同未入职', '工价原因放弃入职',
    '对住宿不满意', '不适应岗位', '不适应上夜班', '综合未达标', '返乡就业', '返乡照顾家人', '晋升慢、机会有限', '身体健康因素', '亲友不在身边', '工作重复单调、枯燥', '与同事相处不和谐'],
  resignationTags: [
    {
      title: '辞职',
      value: 'resignation',
    },
    {
      title: '自离',
      value: 'selfDissociation',
    },
    {
      title: '工期满转正',
      value: 'worker',
    },
    {
      title: '工厂开除',
      value: 'factoryDismissal',
    },
    {
      title: '放弃入职',
      value: 'giveUp',
    },
  ],
  // 报名阶段标签
  signUpTags1: ['精神异常', '无身份证', '考试不过', '有案底', '残疾聋哑', '纹身不过', '喝酒熬夜怕体检不过', '刀烟疤不过', '临时有事放弃', '失联电话不接', '睡过头错过时间',
    '身份证过期', 'AI测试不过', '体内有铁不过', '不想体检', '工资流水证明不过', '没有钱体检', '不想过安检门', '想等朋友一起报名', '下雨放弃', '被同行挖走', '朋友不来跟着放弃'],
  signUpTags: [
    {
      title: '精神异常',
      value: 'psychosis',
    },
    {
      title: '无身份证',
      value: 'noIdCard',
    },
    {
      title: '大花臂',
      value: 'bigFlowerArm',
    },
    {
      title: '有案底',
      value: 'record',
    },
    {
      title: '在外地',
      value: 'field',
    },
  ],

  // 面试阶段标签
  interviewTags1: ['现场放弃', '身份证过期', '精神异常', '有案底', '纹身不过', '联系不上', '考试不过', '体检不过', 'AI测试不过', '刀烟疤不过', '残疾聋哑', '综合考评不过', '体内有铁不过',
    '朋友未过一起放弃', '工资流水证明不过', '人到招募中心放弃', '手机卡不是本人'],
  interviewTags: [
    {
      title: '现场放弃',
      value: 'giveUp',
    },
    {
      title: '身份证过期',
      value: 'expiredIdCard',
    },
    {
      title: '精神异常',
      value: 'psychosis',
    },
    {
      title: '纹身不过',
      value: 'tattoos',
    },
    {
      title: '联系不上',
      value: 'unContact',
    },
    {
      title: '考试不过',
      value: 'failedExam',
    },
    {
      title: '体检不过',
      value: 'failedText',
    },
  ],

  // 待入职标签
  employedTags1: ['联系不上', '放弃入职', '临时有事', '综合未达标', '签合同未入职', '对住宿不满意', '新进未报到培训', '手机卡收不到验证码', '手机卡不是本人', '朋友未过一起放弃', '工价原因放弃入职'],
  employedTags: [
    {
      title: '联系不上',
      value: 'unContact',
    },
    {
      title: '放弃入职',
      value: 'resignation',
    },
    {
      title: '临时有事',
      value: 'busy',
    },
  ],

  // 无意向标签
  noSignUpTags: [
    {
      title: '精神异常',
      value: 'psychosis',
    },
    {
      title: '无身份证',
      value: 'noIdCard',
    },
    {
      title: '大花臂',
      value: 'bigFlowerArm',
    },
    {
      title: '有案底',
      value: 'record',
    },
    {
      title: '在外地',
      value: 'field',
    },
  ],
  // 供应商等级
  supplierGrade: [
    { label: 'A', value: 'SUPPLIER_GRADE_A' },
    { label: 'B', value: 'SUPPLIER_GRADE_B' },
    { label: 'C', value: 'SUPPLIER_GRADE_C' },
  ],
  // 供应商等级
  memberStatus: [
    { label: '无', value: 'NONE' },
    { label: '报名待处理', value: 'SIGN_UP_PENDING' },
    { label: '无意向', value: 'SIGN_UP_NO_INTENTION' },
    { label: '面试待处理', value: 'INTERVIEW_PENDING' },
    { label: '未去面试', value: 'INTERVIEW_NO_ARRIVE' },
    { label: '未通过', value: 'INTERVIEW_FAIL' },
    { label: '入职待处理', value: 'ON_BOARDING_PENDING' },
    { label: '未报到', value: 'ON_BOARDING_FAIL' },
    { label: '在职', value: 'JOB_ON' },
    { label: '预离职', value: 'PREPARE_JOB_RESIGN' },
    { label: '离职', value: 'JOB_RESIGN' },
  ],

  // 线上抽奖
  personLimit: [
    { label: '无要求', value: false },
    { label: '指定名单', value: true },
  ],
  numLimit: [
    { label: '不限', value: false },
    { label: '限制', value: true },
  ],
  onJobLimit: [
    { label: '无要求', value: 'NONE' },
    { label: '需在职', value: 'JOB' },
  ],
  companyLimit: [
    { label: '所有企业', value: false },
    { label: '部分企业', value: true },
  ],
  sexLimit: [
    { label: '男女不限', value: 'NONE' },
    { label: '女性领取', value: 'FEMALE' },
    { label: '男性领取', value: 'MALE' },
  ],
  typeOfWork: [
    // { label: '工种不限', value: 'NONE' },
    { label: '正式工', value: 'FORMAL_WORKER' },
    { label: '派遣工-小时工', value: 'DISPATCH_HOURLY_WORKER' },
    { label: '派遣工-同工同酬', value: 'DISPATCH_EQUAL_PAY' },
  ],
  totalLimit: [
    { label: '无限制', value: false },
    { label: '限制次数', value: true },
  ],
  isOnPublish: [
    { label: '上线', value: true },
    { label: '下线', value: false },
  ],
  isDynamics: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
  docuType: [
    { label: 'WORD文档', value: 'WORD' },
    { label: '表格', value: 'EXCEL' },
    { label: 'PPT演示文档', value: 'PPT' },
    { label: 'PDF文档', value: 'PDF' },
    { label: '图片格式', value: 'IMAGE' },
    { label: '其他', value: 'OTHER' },
  ],
  weekOptions: [
    { label: '周一', value: 'MONDAY' },
    { label: '周二', value: 'TUESDAY' },
    { label: '周三', value: 'WEDNESDAY' },
    { label: '周四', value: 'THURSDAY' },
    { label: '周五', value: 'FRIDAY' },
    { label: '周六', value: 'SATURDAY' },
    { label: '周日', value: 'SUNDAY' },
    { label: '无', value: 'WEEK_NONE' },
  ],
  weekStatus: [
    { label: '进行中', value: 'PROGRESSING' },
    { label: '正常', value: 'NORMAL' },
    { label: '待人工审核', value: 'PENDING_AUDIT' },
    { label: '人工审核通过', value: 'AUDIT_PASS' },
    { label: '人工审核不通过', value: 'AUDIT_FAIL' },
    { label: '人工审核超时', value: 'AUDIT_TIME_OUT' },
  ],
  proveStatus: [
    { label: '待人工审核', value: 'PROCESSING' },
    { label: '确认有风险', value: 'AT_RISK' },
    { label: '确认无风险', value: 'NO_RISK' },
  ],
  proveType: [
    { label: '人工审核', value: 'ARTIFICIAL' },
    { label: '系统审核', value: 'SYSTEM' },
  ],
  dateOptions: [
    { label: '入职第1天', value: 'ENTRY_VISIT_ENTRY_1' },
    { label: '入职第2天', value: 'ENTRY_VISIT_ENTRY_2' },
    { label: '入职第3天', value: 'ENTRY_VISIT_ENTRY_3' },
    { label: '入职第4天', value: 'ENTRY_VISIT_ENTRY_4' },
    { label: '入职第5天', value: 'ENTRY_VISIT_ENTRY_5' },
    { label: '入职第6天', value: 'ENTRY_VISIT_ENTRY_6' },
    { label: '入职第7天', value: 'ENTRY_VISIT_ENTRY_7' },
    { label: '入职第15天', value: 'ENTRY_VISIT_ENTRY_15' },
    { label: '入职第30天', value: 'ENTRY_VISIT_ENTRY_30' },
    { label: '入职第45天', value: 'ENTRY_VISIT_ENTRY_45' },
    { label: '入职第60天', value: 'ENTRY_VISIT_ENTRY_60' },
  ],
  operatorEnum: [
    'EQUAL', // 等于
    'NOT_EQUAL', // 不等于
    'IS_NULL', // 为null
    'IS_NOT_NULL', // 不为NULL
    'GT', // 大于
    'GTE', // 大于等于
    'LT', // 小于
    'LTE', // 小于或等于
    'START_WITH', // 开头匹配
    'CONTAINS', // 包含
    'NOT_CONTAINS', // 不包含
    'DATE_CONTAINS', // 日期包含
    'DATE_NOT_CONTAINS', // 日期不包含
  ],
  month: month(),
  weekDays: weekDays(),
  frequencyOptions: [{ label: '每月', value: 'MONTHLY', data: month() }, { label: '每周', value: 'WEEKLY', data: weekDays() }],
  longStubOptions: [
    { label: '短线', value: 'STUB' },
    { label: '长线(元/小时)', value: 'LONG_LINE' },
    { label: '长线(元/天)', value: 'LONG_LINE_DAY' },
    { label: '其它', value: 'OTHER' },
  ],
};
