import React, { useEffect, useState } from 'react';
import { Transfer } from 'antd';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import { httpRequest } from '@utils';

// 自定义穿梭框选择
const TransferSelect = ({ requestUrl, setData, targetKeysList }) => {
  const [targetKeys, setTargetKeys] = useState([]);
  const getData = async () => {
    const { data } = await httpRequest.get(requestUrl);
    // 相应的信息，必须是label，和value
    const responseData = data.map((c) => ({
      title: c.label,
      key: c.value,
    }));
    return responseData;
  };
  const {
    data,
  } = useQuery(requestUrl, getData);

  useEffect(() => {
    setTargetKeys(targetKeysList);
  }, [targetKeysList]);

  useEffect(() => {
    setData(targetKeys);
  }, [targetKeys]);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  return (
    <Transfer
      showSearch
      dataSource={data}
      titles={['未选数据', '已选数据']}
      targetKeys={targetKeys}
      onChange={onChange}
      render={(item) => item.title}
      listStyle={{
        width: 350,
        height: 300,
      }}
    />
  );
};
TransferSelect.propTypes = {
  setData: PropTypes.func.isRequired,
  targetKeysList: PropTypes.shape([]),
  requestUrl: PropTypes.string,
};
// 默认左侧数据
TransferSelect.defaultProps = {
  targetKeysList: [],
  requestUrl: '',
};
export default TransferSelect;
