import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import { datetimeFormat } from '@constants';

export const hours = _.range(0, 24);
export const minutes = _.range(0, 60);

export const getMenusFromRoutes = (routes, permissions, path = '') => {
  const menus = [];
  let currentMenuKeys = [];
  let childrenArry = [];
  routes.forEach(({ options, children }) => {
    if (!options?.key) {
      return;
    }
    if (options?.link === path || (options?.link !== '/' && path.indexOf(options?.link) === 0)) {
      currentMenuKeys.push(options.key);
    }
    if (!options.permissionKey || options.allowAccess || permissions?.includes(options.permissionKey)) {
      const menu = {
        ...options,
        visible: options.displayInMenu !== false,
      };
      if (children?.length) {
        const childRes = getMenusFromRoutes(children, permissions, path);
        if (childRes?.menus) {
          childrenArry = childRes?.menus.filter((item) => (permissions?.includes(item.key) || options.allowAccess));
        }
        // menu.children = childRes?.menus;
        menu.children = childrenArry;
        currentMenuKeys = currentMenuKeys.concat(childRes?.currentMenuKeys || []);
      }
      menus.push(menu);
    }
  });
  return { menus, currentMenuKeys };
};

export const disableMinutes = ({
  punchInTime, punchOutTime, punchOutEndTime,
}, selectedHour) => {
  const isPunchOutNextDay = moment(punchOutEndTime || punchOutTime).isBefore(moment(punchInTime));
  return minutes.filter((min) => {
    const current = moment(
      `${numeral(selectedHour).format('00')}:${numeral(min).format('00')}`,
      datetimeFormat.hourMin,
    );

    const isSameOrAfterPunchIn = current.isSameOrAfter(moment(punchInTime));
    const isSameOrBeforePunchOut = current.isSameOrBefore(moment(punchOutEndTime || punchOutTime));
    return isPunchOutNextDay ? (
      isSameOrAfterPunchIn || isSameOrBeforePunchOut
    ) : (
      isSameOrAfterPunchIn && isSameOrBeforePunchOut
    );
  });
};

export const disableHours = ({
  punchInTime, punchOutTime,
}) => {
  const isPunchOutNextDay = moment(punchOutTime).isBefore(moment(punchInTime));
  return hours.filter((hour) => {
    const isPunchInBeforeHour = moment(punchInTime).isBefore(
      moment(
        `${numeral(hour).format('00')}:${numeral(moment(punchInTime).minutes() - 1).format('00')}`,
        datetimeFormat.hourMin,
      ),
    );
    const isPunchOutAfterHour = moment(punchOutTime).isAfter(
      moment(
        `${numeral(hour).format('00')}:${numeral(moment(punchOutTime).minutes()).format('00')}`,
        datetimeFormat.hourMin,
      ),
    );
    return isPunchOutNextDay ? (
      isPunchInBeforeHour || isPunchOutAfterHour
    ) : (
      isPunchInBeforeHour && isPunchOutAfterHour
    );
  });
};

export const disablePunchInStart = ({
  punchInTime, punchOutTime, punchOutEndTime,
}) => disableHours({
  punchInTime: moment(punchOutEndTime || punchInTime).format(),
  punchOutTime: moment(punchOutTime).format(),
});

export const disablePunchInEnd = ({
  punchInTime, punchOutTime, punchOutStartTime,
}) => disableHours({
  punchInTime: moment(punchOutStartTime || punchOutTime).format(),
  punchOutTime: moment(punchInTime).format(),
});

export const disablePunchOutStart = ({
  punchInTime,
  punchOutTime,
  punchInEndTime,
}) => disableHours({
  punchInTime: moment(punchOutTime).format(),
  punchOutTime: moment(punchInEndTime || punchInTime).format(),
});

export const disablePunchOutEnd = ({
  punchInTime,
  punchInStartTime,
  punchOutTime,
}) => disableHours({
  punchInTime: moment(punchInStartTime || punchInTime).format(),
  punchOutTime: moment(punchOutTime).format(),
});

export default {};
