import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const DebitManageList = lazy(() => import('@/modules/financialManage/advanceManage/debitManage'));
const RepaymentManage = lazy(() => import('@/modules/financialManage/advanceManage/repaymentManage'));
const SupplierReconciliation = lazy(() => import('@/modules/financialManage/advanceManage/supplierReconciliation'));
const MemberSalary = lazy(() => import('@modules/financialManage/salarySlip/member'));
const InnerSalary = lazy(() => import('@modules/financialManage/salarySlip/inner'));

export default {
  path: 'financialManage',
  element: <Outlet />,
  options: {
    title: '财务管理',
    permissionKey: 'menu-financialManage',
    key: 'menu-financialManage',
    icon: <Icon.ContactsOutlined />,
    link: '/financialManage',
    allowAccess: true,
  },
  children: [
    {
      path: 'advanceManage',
      element: <Outlet />,
      options: {
        title: '预支管理',
        key: 'menu-financialManage-advanceManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/financialManage/advanceManage',
        allowAccess: true,
      },
      children: [
        {
          path: 'debitManage',
          element: <Outlet />,
          options: {
            title: '借支审核',
            key: 'menu-financialManage-debitManage',
            icon: <Icon.FileOutlined />,
            link: '/financialManage/advanceManage/debitManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="借支审核" permissionKey="menu-financialManage-debitManage" component={<DebitManageList />} />,
            },
          ],
        }, {
          path: 'repaymentManage',
          element: <Outlet />,
          options: {
            title: '还款冲销',
            key: 'menu-financialManage-repaymentManage',
            icon: <Icon.FileOutlined />,
            link: '/financialManage/advanceManage/repaymentManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="还款冲销" permissionKey="menu-financialManage-repaymentManage" component={<RepaymentManage />} />,
            },
          ],
        }, {
          path: 'supplierReconciliation',
          element: <Outlet />,
          options: {
            title: '供应商对账',
            key: 'menu-financialManage-supplierBill',
            icon: <Icon.FileOutlined />,
            link: '/financialManage/advanceManage/supplierReconciliation',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="供应商对账" permissionKey="menu-financialManage-supplierBill" component={<SupplierReconciliation />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'salarySlip',
      element: <Outlet />,
      options: {
        title: '掌上工资条',
        key: 'menu-financialManage-salarySlip',
        icon: <Icon.FolderOpenOutlined />,
        link: '/financialManage/salarySlip',
        allowAccess: true,
      },
      children: [
        {
          path: 'memberSalary',
          element: <Outlet />,
          options: {
            title: '会员工资',
            key: 'menu-salarySlip-member',
            icon: <Icon.FileOutlined />,
            link: '/financialManage/salarySlip/memberSalary',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="会员工资" permissionKey="menu-salarySlip-member" component={<MemberSalary />} />,
            },
          ],
        },
        {
          path: 'innerSalary',
          element: <Outlet />,
          options: {
            title: '内部工资',
            key: 'menu-salarySlip-inner',
            icon: <Icon.FileOutlined />,
            link: '/financialManage/salarySlip/innerSalary',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="内部工资" permissionKey="menu-salarySlip-inner" component={<InnerSalary />} />,
            },
          ],
        },
      ],
    },
  ],
};
