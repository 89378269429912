/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import {
  Upload, Modal, Spin, message, Button,
} from 'antd';
import * as Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';

const action = () => process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOADFILES_URL;

const BatchUploadFiles = ({
  fileType, fileList, fileChange, beforeUpload, visible,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [files, setFiles] = useState([]);
  const [fileLists, setFileFlists] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [newfileLists, setNewFileFlists] = useState([]);

  useEffect(() => {
    if (fileList) {
      setFiles(fileList);
    }
  }, [fileList]);

  // 解析前端file的信息，给到后端
  const parseFiles = (targetFiles) => targetFiles.map((val) => {
    const newFileObj = {};
    newFileObj.fileKey = val.fileKey;
    newFileObj.url = val.url;
    newFileObj.md5 = val.md5;
    newFileObj.name = val.name;
    return newFileObj;
  });

  const checkPicture = (fileName) => /\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName);

  const handlePreview = async (file) => {
    if (file.name && checkPicture(file.name)) {
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else {
      window.open(file.url, '_blank');
    }
  };

  const handleCancel = () => setPreviewVisible(false);
  const onRemove = (file) => {
    const newFileList = [...(files.filter((val) => !(val.url === file.url)))];
    setFiles(newFileList);
    fileChange(parseFiles(newFileList));
  };

  // 上传文件之前的方法
  const beforeUploads = (file) => {
    const fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
    const fileNameLength = file.name.length;// 取到文件名长度
    const fileFormat = file.name.substring(fileName, fileNameLength);// 截
    if (fileFormat !== '.pdf') {
      message.error('上传文件必须为.pdf类型');
      return false;
    }
    return true;
  };

  const fileRemove = (arry) => {
    const index = arry.findIndex((item) => item.status !== 'uploading');
    if (index > -1) {
      arry.splice(index, 1);
    }
    return arry;
  };

  const findItem = (arr1, arr2) => {
    const arr = [];
    arr1.map((item) => {
      if (arr2.every((val) => val === item.md5)) {
        arr.push('true');
      } else {
        arr.push('false');
      }
      return item;
    });
    return arr;
  };

  // 上传文件之前
  const beforeUploadFile = (file, filesList) => {
    const fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
    const fileNameLength = file.name.length;// 取到文件名长度
    const fileFormat = file.name.substring(fileName, fileNameLength);// 截
    setIsUpload(true);
    setNewFileFlists(filesList);
    return false;
  };

  const upload = async () => {
    if (!beforeUpload()) {
      return;
    }
    try {
      setLoading(true);
      let isRepeat = false;
      const fileMd5 = [];
      newfileLists?.map(async (item) => {
        await FileMD5(item).then((res) => {
          fileMd5.push(res);
        }).catch((err) => {
          message.error('计算md5失败');
        });
        return item;
      });
      setTimeout(async () => {
        files?.forEach((vals) => {
          if (fileMd5.includes(vals.md5)) {
            isRepeat = true;
          }
        });
        if (isRepeat) {
          message.warning('该文件已上传');
          return;
        }
        const formData = new FormData();
        for (const item of newfileLists) {
          formData.append('files', item);
        }
        await axios({
          method: 'post',
          url: action(),
          data: formData,
          timeout: 30000,
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Device': 'pc',
            'X-User-Token': Cookies.get(storageKeys.token) || '',
            'X-Tenant-Code': Cookies.get(storageKeys.tenantCode) || '',
          },
        }).then((res) => {
          if (res.data && res.data.code === 0) {
            const respData = res.data.data;
            respData.map((item, index) => {
              Object.assign(item, {
                md5: fileMd5[index],
                name: newfileLists[index].name,
              });
              return item;
            });
            const newFiles = [...files, ...res.data.data];
            setFiles(newFiles);
            fileChange(parseFiles(newFiles));
          } else {
            message.error('上传失败');
          }
        });
      }, 400);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (info) => {
    setIsUpload(false);
  };

  useEffect(() => {
    fileRemove(fileLists);
    setFileFlists(fileRemove(fileLists));
  }, [fileLists]);

  useEffect(() => {
    if (isUpload) {
      upload();
    }
  }, [isUpload]);

  const uploadButton = (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <Icon.PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        上传
      </div>
    </div>
  );

  return (
    <>
      <Spin spinning={loading}>
        <Upload
          listType={fileType === '.pdf' ? '' : 'picture-card'}
          fileList={files}
          beforeUpload={beforeUploadFile}
          onPreview={handlePreview}
          onRemove={onRemove}
          onChange={onChange}
          accept={fileType}
          multiple
          showUploadList={fileType !== '.pdf'}
        >
          {fileType === '.pdf' ? <Button type="primary">上传合同</Button> : visible ? uploadButton : null}
        </Upload>
      </Spin>
      {
        fileType === '.pdf' ? '' : (
          <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} animation={false}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        )
      }
    </>
  );
};
BatchUploadFiles.propTypes = {
  fileType: PropTypes.string,
  fileList: PropTypes.instanceOf(Array),
  fileChange: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
  visible: PropTypes.bool,
};

BatchUploadFiles.defaultProps = {
  fileList: [],
  fileType: 'image/*', // image/*
  beforeUpload: () => true,
  visible: true,
};

export default BatchUploadFiles;
