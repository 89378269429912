import React, {
  useEffect, useState, forwardRef, useImperativeHandle,
} from 'react';
import {
  Upload, Modal, Spin, message, Button,
} from 'antd';
import * as Icon from '@ant-design/icons';
import { awardHttp } from '@utils';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileMD5 from '@utils/md5';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';

const action = () => process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_UPLOAD_URL;

const UploadFileList = forwardRef(({
  fileType, fileList, fileChange, beforeUpload, contractTempId,
}, ref) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [files, setFiles] = useState([]);
  const [personsData, setPersonsData] = useState([]); // 上传成功以后返回的名单数据

  useEffect(() => {
    if (fileList) {
      setFiles(fileList);
    }
  }, [fileList]);

  // 解析前端file的信息，给到后端
  const parseFiles = (targetFiles) => targetFiles.map((val) => {
    const newFileObj = {};
    newFileObj.fileKey = val.fileKey;
    newFileObj.url = val.url;
    newFileObj.md5 = val.md5;
    newFileObj.name = val.name;
    return newFileObj;
  });

  const checkPicture = (fileName) => /\.(pdf)$/.test(fileName);

  const handlePreview = async (file) => {
    if (file.name && checkPicture(file.name)) {
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    } else {
      window.open(file.url, '_blank');
    }
  };

  const handleCancel = () => setPreviewVisible(false);
  const onRemove = (file) => {
    const newFileList = [...(files.filter((val) => !(val.url === file.url)))];
    setFiles(newFileList);
    fileChange(parseFiles(newFileList));
  };

  // 上传文件之前的方法
  const beforeUploadFile = (file) => {
    const fileName = file.name.lastIndexOf('.');// 取到文件名开始到最后一个点的长度
    const fileNameLength = file.name.length;// 取到文件名长度
    const fileFormat = file.name.substring(fileName, fileNameLength);// 截
    if (fileFormat !== ('.xls' || '.xlsx')) {
      message.error('上传文件必须为.xls类型');
      return false;
    }
    return true;
  };

  // 上传名单解析
  const uploadFile = async (prams) => {
    const res = await awardHttp.post('/admin/lotteryActivity/persons/import', prams);
    if (res.code === 0) {
      setPersonsData(res.data);
    }
  };

  // 自定义上传操作
  const customRequest = async ({ file }) => {
    if (!beforeUpload()) {
      return;
    }
    try {
      setLoading(true);
      let fileMd5 = '';
      await FileMD5(file).then((e) => {
        fileMd5 = e;
      }).catch((err) => {
        message.error('计算md5失败');
      });
      const isRepeat = files.some((val) => val.md5 === fileMd5);
      if (isRepeat) {
        message.warning('该文件已上传');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      await axios({
        method: 'post',
        url: action(),
        data: formData,
        timeout: 30000,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Device': 'pc',
          'X-User-Token': Cookies.get(storageKeys.token) || '',
          'X-Tenant-Code': Cookies.get(storageKeys.tenantCode) || '',
        },
      }).then((res) => {
        if (res.data && res.data.code === 0) {
          const respData = res.data.data;
          const newFile = file;
          // newFile.uid = Math.round(Math.random() * -1000000000);
          newFile.fileKey = respData.fileKey;
          newFile.url = respData.url;
          newFile.md5 = fileMd5;
          const newFiles = [...files, newFile];
          setFiles(newFiles);
          fileChange(parseFiles(newFiles));
          uploadFile(formData);
        } else {
          message.error('上传失败');
        }
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => personsData);

  return (
    <Spin spinning={loading}>
      <Upload
        fileList={files}
        listType={fileType === '.xlsx' ? '' : 'text'}
        onPreview={handlePreview}
        customRequest={customRequest}
        onRemove={onRemove}
        accept={fileType}
      >
        <Button type="primary">点击上传名单</Button>
      </Upload>
    </Spin>
  );
});
UploadFileList.propTypes = {
  fileType: PropTypes.string,
  contractTempId: PropTypes.string,
  fileList: PropTypes.instanceOf(Array),
  fileChange: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func, // 上传前校验，默认返回TRUE不校验
};

UploadFileList.defaultProps = {
  fileList: [],
  fileType: '.xlsx',
  contractTempId: '',
  beforeUpload: () => true,
};

export default UploadFileList;
