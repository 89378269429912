import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import userInfo from './userInfo';

const RoleManagement = lazy(() => import('./roleManagement'));
const AccountManagement = lazy(() => import('./accountManagement'));
const Organization = lazy(() => import('./organization'));
const StoreManagement = lazy(() => import('./store/record'));

export default {
  path: 'setting',
  element: <Outlet />,
  options: {
    title: '系统管理',
    permissionKey: 'menu-systemManage',
    key: 'menu-systemManage',
    icon: <Icon.DesktopOutlined />,
    link: '/setting',
  },
  children: [
    {
      path: 'userInfo',
      element: <Outlet />,
      options: {
        title: '用户管理',
        permissionKey: 'menu-baseInfo-userManage',
        key: 'menu-baseInfo-userManage',
        icon: <Icon.FileOutlined />,
        link: '/setting/userInfo',
      },
      children: userInfo,
    },
    {
      path: 'accountManagement',
      element: <ProtectedRoute permissionKey="menu-systemManage-accountManage" title="账号管理" component={<AccountManagement />} />,
      options: {
        title: '账号管理',
        permissionKey: 'menu-systemManage-accountManage',
        key: 'menu-systemManage-accountManage',
        icon: <Icon.FileOutlined />,
        link: '/setting/accountManagement',
      },
    },
    {
      path: 'organization',
      element: <Outlet />,
      options: {
        title: '组织架构',
        key: 'menu-baseInfo-organization',
        icon: <Icon.FileOutlined />,
        link: '/setting/organization',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-baseInfo-organization" title="组织架构" component={<Organization />} />,
        },
      ],
    },
    {
      path: 'storeManagement',
      element: <Outlet />,
      options: {
        title: '门店管理',
        key: 'menu-baseInfo-storeManage',
        icon: <Icon.FileOutlined />,
        link: '/setting/storeManagement',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute permissionKey="menu-baseInfo-storeManage" title="门店管理" component={<StoreManagement />} />,
        },
      ],
    },
    {
      path: 'roleManagement',
      element: <ProtectedRoute permissionKey="menu-systemManage-roleManage" title="角色管理" component={<RoleManagement />} />,
      options: {
        title: '角色管理',
        permissionKey: 'menu-systemManage-roleManage',
        key: 'menu-systemManage-roleManage',
        icon: <Icon.FileOutlined />,
        link: '/setting/roleManagement',
      },
    },
  ],
};
