/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';
import { SideMenu } from '@/components';
import authStore from '@/stores/auth';
import appRoutes from '@/routes/appRoutes';
import { StyledLayout as Layout, Logo, MenuCollapsedButton } from './styles';
import {
  getMenusFromRoutes,
} from './utils';
import icon from '@/assets/zd/icon.png';
import logo from '@/assets/zd/logo.png';
import ypLogo from '@/assets/zd/ypLogo.png';
import ycLogo from '@/assets/zd/ycLogo.png';
import yhLogo from '@/assets/zd/yhLogo.png';

const MENU_COLLAPSED_KEY = 'menu-collapsed';

const Sider = () => {
  const location = useLocation();
  const params = useParams();
  const [collapsed, setCollapsed] = useState(localStorage.getItem(MENU_COLLAPSED_KEY) === 'true');
  const [isOpen, setIsOpen] = useState(false);
  const tenantCode = Cookies.get(storageKeys.tenantCode);
  let staticPath = location.pathname;
  if (params) {
    Object.keys(params).forEach((key) => {
      staticPath = staticPath.replace(params[key], `:${key}`);
    });
  }
  const { menus, currentMenuKeys } = getMenusFromRoutes(appRoutes || [], authStore.permissions, staticPath);

  const toggleCollapsed = () => {
    localStorage.setItem(MENU_COLLAPSED_KEY, !collapsed);
    setCollapsed(!collapsed);
  };

  const handleBooleanChange = (boolValue) => {
    setIsOpen(boolValue);
  };

  const logoUrl = tenantCode === 'yp' ? ypLogo
    : tenantCode === 'zpwl' ? ycLogo
      : tenantCode === 'yh' ? yhLogo
        : collapsed ? icon : logo;

  return (
    <Layout.Sider collapsed={collapsed} theme="light" width={isOpen ? 300 : 150}>
      {/* <Layout.Sider collapsed={collapsed} theme="light"> */}
      <Logo to="/">
        <img src={logoUrl} alt={process.env.REACT_APP_WEBSITE_TITLE} />
        <h1>{ process.env.REACT_APP_WEBSITE_TITLE }</h1>
      </Logo>
      <div className="menu">
        <SideMenu
          menus={menus}
          selectedKeys={currentMenuKeys}
          defaultOpenKeys={currentMenuKeys}
          menuSelectFun={handleBooleanChange}
        />
      </div>
      <MenuCollapsedButton
        type="text"
        onClick={toggleCollapsed}
        style={{ marginBottom: 16 }}
      >
        <MenuOutlined />
      </MenuCollapsedButton>
    </Layout.Sider>
  );
};

export default observer(Sider);
