import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';

const { SubMenu } = Menu;

const SideMenu = ({
  menus, selectedKeys, defaultOpenKeys, menuSelectFun,
}) => {
  const navigate = useNavigate();
  const rootSubmenuKeys = menus.map(({ key }) => key);
  const [openKeys, setOpenKeys] = useState(['']); // 一级菜单栏
  const [subItemMenu, setSubItemMenu] = useState([]); // 一级菜单下的子菜单

  useEffect(() => {
    setOpenKeys(defaultOpenKeys);
  }, [defaultOpenKeys]);

  useEffect(() => {
    const subItem = menus?.filter((f) => f.key === selectedKeys[0]);
    setSubItemMenu(subItem);
    menuSelectFun(!!subItem[0]?.children?.length);
  }, [selectedKeys]);

  // 一级菜单展开事件
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menuSelect = (item, key, keyPath, selectedKeys1, domEvent) => {
    const subItem = menus?.filter((f) => f.key === item.key);
    setSubItemMenu(subItem);
    menuSelectFun(!!subItem[0].children?.length);
  };

  const handleMenuClick = ({ item }, menu) => {
    navigate(item.props.link, { state: { title: menu.title } });
  };

  const renderSubMenu = (list) => list?.map((menu) => (menu.visible && (
    menu.children && menu.children.length && menu.children.find((m) => m.visible) ? (
      <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
        {
          renderSubMenu(menu.children)
        }
      </SubMenu>
    ) : (
      <Menu.Item
        key={menu.key}
        icon={menu.icon}
        onClick={(props) => handleMenuClick(props, menu)}
        link={menu.link}
      >
        {menu.title}
      </Menu.Item>
    )
  )));

  // 一级菜单栏
  const renderMenu = (list) => list.map((menu) => (menu.visible && (
    (
      <Menu.Item
        key={menu.key}
        icon={menu.icon}
        onClick={(props) => handleMenuClick(props, menu)}
        link={menu.link}
      >
        {menu.title}
      </Menu.Item>
    )
  )));

  // 子菜单
  const renderMenuSubMunu = (list) => list?.map((menu) => (menu.visible && (
    menu?.children && menu?.children.length && menu?.children?.map((item) => (
      item.children && item.children.length && item.children.find((m) => m.visible) ? (
        <SubMenu key={item.key} icon={item.icon} title={item.title}>
          {
            renderSubMenu(item.children)
          }
        </SubMenu>
      ) : (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          onClick={(props) => handleMenuClick(props, item)}
          link={item.link}
        >
          {item.title}
        </Menu.Item>
      )
    ))
  )));

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '100vh' }}>
      <Menu
        mode="inline"
        theme="light"
        inlineIndent={24}
        // openKeys={openKeys}
        // onOpenChange={onOpenChange}
        selectedKeys={selectedKeys}
        onSelect={menuSelect}
      >
        { renderMenu(menus) }
      </Menu>
      <Menu
        mode="inline"
        theme="light"
        inlineIndent={15}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={selectedKeys}
      >
        { renderMenuSubMunu(subItemMenu) }
      </Menu>
    </div>
  );
};

const MENU_SHAPE = (...args) => PropTypes.shape({
  title: PropTypes.string,
  key: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string,
  children: PropTypes.arrayOf(MENU_SHAPE),
}).apply(this, args);

SideMenu.propTypes = {
  menus: PropTypes.arrayOf(MENU_SHAPE),
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  menuSelectFun: PropTypes.func,
};

SideMenu.defaultProps = {
  menus: [],
  selectedKeys: [],
  defaultOpenKeys: [],
  menuSelectFun: () => {},
};

export default SideMenu;
