/*
 * @Author: TANG
 * @Date: 2022-06-24 11:37:08
 * @LastEditors: TANG 15390913806@163.com
 * @LastEditTime: 2022-06-25 15:13:39
 */

import { accountHttp } from '@utils';

export default class LoginApi {
  static Login(params) {
    return accountHttp.post('/noAuth/login/pc', params);
  }

  static tenantSelect(account) {
    return accountHttp.get(`/noAuth/login/${account}/tenant/list`);
  }

  // 发送验证码登录验证码
  static sendCAPTCHACode(params) {
    return accountHttp.post('/noAuth/sms/sendSmsCode/login', params);
  }

  // 发送忘记密码验证码
  static sendForgetCAPTCHACode(params) {
    return accountHttp.post('/noAuth/sms/sendSmsCode/forgetPwd', params);
  }

  // 重置密码
  static resetPassword(parms) {
    return accountHttp.post('/noAuth/login/changePwd', parms);
  }
}
