import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form, Row, Col, Button, Space, Card, Spin, message,
} from 'antd';
import moment from 'moment';
import { httpRequest, accountHttp } from '@utils';
import Watermark from 'antd-watermark';
import UserInfo from '@stores/userInfo';
import md5 from 'md5';
import { Content } from 'antd/lib/layout/layout';
import { FormItem, TransferSelect } from '@/components';
import { options as optionHooks } from '@/hooks';
import { EmptyFormItem } from './styles';
import {
  getBasicFormFields, getBaseFormFieldsBr, getAuthorizeFormFields, getBelongStoreFields, getRoleFormFields, getAccountFields,
} from './formFields';

const UserForm = () => {
  const [form] = Form.useForm();
  const [accountForm] = Form.useForm();
  const navigate = useNavigate();
  const queryParams = useParams();
  const departments = optionHooks.useDepartments(); // 定义部门下拉选项列表
  const roleData = optionHooks.useRoles(); // 定义角色下拉选项列表
  const storeData = optionHooks.useStores(); // 定义门店下拉选项列表
  const [admin, setAdmin] = useState(false);
  const [resident, setResident] = useState(false);
  const [residentManager, setResidentManager] = useState(false);
  const [finance, setFinance] = useState(false);
  const [roomManager, setRoomManager] = useState(false);
  const [loading, setLoading] = useState(false);
  const accountIdRef = useRef(''); // 账号id
  const [groupData, setGroupData] = useState([]); // 定义小组下拉选项列表
  const { userId } = queryParams; // 获取url中的userId

  const initialValues = {
    loginAccount: '',
    roleIds: ['62c561974844402adafebd23'],
    source: 'RECRUIT',
    userId: '',
    loginPassword: '',
  };

  // 设置负责工厂方法和数组
  const setControlCompanyData = (controlCompanyDataIds) => {
    form.setFieldsValue({
      controlCompanyIds: controlCompanyDataIds,
    });
  };
  const [targetKeysByControlCompany, setTargetKeysByControlCompany] = useState([]); // 初始化负责的工厂

  // 设置授权工厂方法和数组
  const setAuthorizeCompanyData = (authorizeCompanyDataIds) => {
    form.setFieldsValue({
      authorizeCompanyIds: authorizeCompanyDataIds,
    });
  };
  const [targetKeysByAuthorizeCompany, setTargetKeysByAuthorizeCompany] = useState([]); // 初始化授权的工厂

  // 设置授权门店方法和数组
  const setAuthorizeStoreData = (authorizeStoreDataIds) => {
    form.setFieldsValue({
      authorizeStoreIds: authorizeStoreDataIds,
    });
  };
  const [targetKeysByAuthorizeStore, setTargetKeysByAuthorizeStore] = useState([]); // 初始化授权的门店

  const onValuesChange = async (changedValues, allValues) => {
    if (allValues.belongStoreId) {
      if (changedValues.belongStoreId) {
        const res = await httpRequest.get(`/common/store/${allValues.belongStoreId}/group/forSelect`);
        if (res.code === 0) {
          setGroupData(res.data);
          form.setFieldsValue({ belongGroupId: '' });
        }
      }
    } else {
      setGroupData([]);
      form.setFieldsValue({ belongGroupId: '' });
    }
  };

  // 刷新
  const refectFun = async (id) => {
    try {
      const res = await accountHttp.get(`/admin/account/clear/${id}`);
      if (res.code === 0) {
        message.info('账号刷新成功！');
      }
    } catch (err) {
      message.error(err);
    }
  };

  // 获取账号信息
  const getAccountInfo = async (userIdData) => {
    try {
      const res = await accountHttp.get(`/admin/account/fetch/accountInfo/${userIdData}`);
      if (res.code === 0) {
        const { data } = res;
        accountForm.setFieldsValue({
          ...data,
        });
        accountIdRef.current = data.accountId;
      } else {
        message.error(res.msg);
      }
    } catch (err) {
      message.error(err?.message || '获取账号信息错误');
    }
  };

  // 如果userId存在，说明是编辑页面
  const getUserInfoByUserId = async () => {
    try {
      setLoading(true);
      const res = await httpRequest.get(`/admin/user/${userId}`);
      if (res.code === 0) {
        const { data } = res;
        form.setFieldsValue({
          ...data,
          entryDate: data?.entryDate ? moment(data.entryDate) : null,
          resignDate: data?.resignDate ? moment(data.resignDate) : null,
          loginPassword: '',
        });
        if (data.belongStoreId) {
          const res1 = await httpRequest.get(`/common/store/${data.belongStoreId}/group/forSelect`);
          if (res1.code === 0) {
            setGroupData(res1.data);
          }
        }
        setAdmin(data.admin);
        setResident(data.resident);
        setResidentManager(data.residentManager);
        setFinance(data.finance);
        setRoomManager(data.roomManager);
        // 初始化数据权限
        setTargetKeysByControlCompany(data.controlCompanyIds);
        setTargetKeysByAuthorizeCompany(data.authorizeCompanyIds);
        setTargetKeysByAuthorizeStore(data.authorizeStoreIds);
      } else {
        message.error(res.msg);
      }
    } catch (err) {
      message.error(err?.message || '获取用户信息错误');
    } finally {
      setLoading(false);
    }
  };
  // 监听userId
  useEffect(() => {
    if (userId) {
      getUserInfoByUserId();
      getAccountInfo(userId);
    }
  }, [userId]);

  const checkAddPage = () => {
    if (userId && userId.length > 0) {
      return false;
    }
    return true;
  };

  const basicFormFields = getBasicFormFields({
    isAddPage: checkAddPage(),
    departments,
  });
  const accountFields = getAccountFields({
    isEdit: checkAddPage(),
  });
  const basicFormFieldsBr = getBaseFormFieldsBr();
  const authorizeFormFields = getAuthorizeFormFields({
    setControlCompanyData,
    targetKeysByControlCompany,
    setAuthorizeCompanyData,
    targetKeysByAuthorizeCompany,
    setAuthorizeStoreData,
    targetKeysByAuthorizeStore,
  });
  const belongStoreFields = getBelongStoreFields(storeData, groupData);
  const roleFormFields = getRoleFormFields({
    admin,
    setAdmin: (val) => setAdmin(val),
    resident,
    setResident: (val) => setResident(val),
    residentManager,
    setResidentManager: (val) => setResidentManager(val),
    finance,
    setFinance: (val) => setFinance(val),
    roleData,
    roomManager,
    setRoomManager: (val) => setRoomManager(val),
  });

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label}不能为空',
  };

  // 新增、编辑账号信息
  const submitAccount = async (newUserId) => {
    try {
      const vals = accountForm.getFieldValue();
      const params = {
        ...vals,
        id: accountIdRef.current,
        // source: 'RECRUIT',
        userId: userId || newUserId,
        loginPassword: vals.loginPassword ? md5(vals.loginPassword) : null,
      };
      if (newUserId || userId) {
        const res = userId ? await accountHttp.post('/admin/account/edit', params) : await accountHttp.post('/admin/account/add', params);
        if (res.code === 0) {
          message.info(userId ? '修改成功' : '添加成功');
          getAccountInfo(res.data.userId);
          setTimeout(() => { refectFun(accountIdRef.current); }, 600);
          setTimeout(() => { navigate('/setting/userInfo'); }, 700);
        }
      } else {
        message.warning('请先保存新建用户信息！');
      }
    } catch (err) {
      message.error(err?.message || userId ? '修改失败' : '新增失败');
    }
  };

  // 提交用户信息
  const handleFormFinish = async (vals) => {
    try {
      setLoading(true);
      const payload = {
        ...vals,
        entryDate: vals?.entryDate ? moment(vals?.entryDate).startOf('day').unix() * 1000 : null,
        resignDate: vals?.resignDate ? moment(vals?.resignDate).startOf('day').unix() * 1000 : null,
        loginPassword: vals?.loginPassword ? md5(vals?.loginPassword) : null,
      };
      const res = userId ? await httpRequest.put(`/admin/user/${userId}`, payload) : await httpRequest.post('/admin/user', payload);
      if (res.code === 0) {
        submitAccount(res.data);
      } else {
        message.error(res.msg);
      }
      if (vals.status === 'RESIGN') {
        await accountHttp.put(`/admin/account/disable/${accountIdRef.current}`);
      }
    } catch (err) {
      message.error(err?.message || '用户保存失败');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Watermark content={`${UserInfo.profile.storeName} - ${UserInfo.profile.userName}`}>
      <Content>
        <Spin spinning={loading}>
          <Card title={userId ? '编辑账号' : '新建账号'} bordered="false">
            <Form form={accountForm} initialValues={initialValues}>
              <Row gutter={[40]}>
                {
                  accountFields.map((f) => (
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <FormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                        inputProps={f.inputProps}
                        type={f.type}
                        rules={f.rules}
                        showAllOption={f.showAllOption}
                      />
                    </Col>
                  ))
                }
              </Row>
            </Form>
          </Card>
          <Form
            form={form}
            onFinish={handleFormFinish}
            validateMessages={validateMessages}
            onValuesChange={onValuesChange}
            initialValues={{
              disable: false,
              admin: 'false',
              resident: 'false',
              residentManager: 'false',
              finance: 'false',
              roomManager: 'false',
              status: 'ON_LINE',
            }}
          >
            <Card title="基本信息" bordered="false">
              <Row gutter={[40]}>
                {
                  basicFormFields.map((f) => (
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <FormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                        inputProps={f.inputProps}
                        type={f.type}
                        rules={f.rules}
                        showAllOption={f.showAllOption}
                      />
                    </Col>
                  ))
                }
              </Row>
              <Row gutter={[40]} bordered="false">
                {
                  basicFormFieldsBr.map((f) => (
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <FormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                        inputProps={f.inputProps}
                        type={f.type}
                        rules={f.rules}
                        showAllOption={f.showAllOption}
                      />
                    </Col>
                  ))
                }
              </Row>
            </Card>
            <Card title="系统权限" bordered="false">
              <Row gutter={[40]}>
                {
                  belongStoreFields.map((f) => (
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={6}>
                      <FormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                        inputProps={f.inputProps}
                        type={f.type}
                        rules={f.rules}
                        showAllOption={f.showAllOption}
                      />
                    </Col>
                  ))
                }
              </Row>
              <Row gutter={[24]} style={{ paddingTop: 20 }}>
                {
                  authorizeFormFields.map((f) => (
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                      <EmptyFormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                      />
                      <TransferSelect requestUrl={f.requestUrl} setData={f.setData} targetKeysList={f.targetKeysList} />
                    </Col>
                  ))
                }
              </Row>
            </Card>
            {/* <Card title="角色分配" bordered="false">
              <Row gutter={[40]}>
                {
                  roleFormFields.map((f) => (
                    <Col span={5}>
                      <FormItem
                        key={f.name}
                        name={f.name}
                        label={f.label}
                        inputProps={f.inputProps}
                        type={f.type}
                        rules={f.rules}
                        showAllOption={f.showAllOption}
                      />
                    </Col>
                  ))
                }
              </Row>
            </Card> */}
            <Card style={{ marginTop: -1, borderTop: 0 }}>
              <Row>
                <Col offset={9}>
                  <Space size={12}>
                    <Button type="primary" htmlType="submit">保存</Button>
                    <Button onClick={() => navigate(-1)}>取消</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Form>
        </Spin>
      </Content>
    </Watermark>
  );
};

export default UserForm;
