import React from 'react';
import KeepAlive from 'react-activation';
import UserForm from '@modules/setting/userInfo/userForm';
import { useLocation } from 'react-router-dom';

function UserNew() {
  const location = useLocation();
  return (
    <KeepAlive id={location.pathname} cacheKey="新增用户" name={location.pathname}>
      <UserForm />
    </KeepAlive>
  );
}
export default UserNew;
