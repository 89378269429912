import { useAliveController } from 'react-activation';
import React from 'react';
import Tab from './Tab';
import './index.less';

const KeepAliveTabs = () => {
  const { getCachingNodes } = useAliveController();
  const cachingNodes = getCachingNodes();
  return (
    <ul className="alive-tabs">
      {cachingNodes.map((node, idx) => (
        <Tab key={node.id} node={node} />
      ))}
    </ul>
  );
};

export default KeepAliveTabs;
