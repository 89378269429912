/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';
import authStore from '@stores/auth';
import documentStore from '@stores/document';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';
import ypFavicon from '@/assets/zd/ypFavicon.png';
import ycFavicon from '@/assets/zd/ycFavicon.png';
import yhFavicon from '@/assets/zd/yhFavicon.png';

const ProtectedRoute = ({
  component,
  permissionKey,
  allowAccess,
  title,
}) => {
  const location = useLocation();
  const tenantCode = Cookies.get(storageKeys.tenantCode);
  const titleSuffix = title ? ` - ${title}` : '';
  useEffect(() => {
    // document.title = `${process.env.REACT_APP_WEBSITE_TITLE}${title ? ` - ${title}` : ''}`;
    document.title = tenantCode === 'yp' ? `业聘人力${titleSuffix}`
      : tenantCode === 'yh' ? `宜宏${titleSuffix}`
        : tenantCode === 'zpwl' ? `云彩人资${titleSuffix}`
          : tenantCode === 'zd' ? `众鼎人力${titleSuffix}`
            : `云彩人资${titleSuffix}`;
    documentStore.setPageTitle(title);
    documentStore.setFavicon(tenantCode === 'yp' ? ypFavicon
      : tenantCode === 'yh' ? yhFavicon
        : tenantCode === 'zpwl' ? ycFavicon
          : tenantCode === 'zd' ? process.env.REACT_APP_FAVICON_URL
            : ycFavicon);
  }, [title, authStore?.profile?.username]);

  if (!authStore.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const isAuthorized = authStore.isAdmin || allowAccess || authStore.permissions?.includes(permissionKey);
  if (!isAuthorized) {
    return <Navigate to="/not-authorized" state={{ from: location }} />;
  }

  return component;
};

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  permissionKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  allowAccess: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  title: '',
  allowAccess: false,
};

export default observer(ProtectedRoute);
