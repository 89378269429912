import { makeAutoObservable } from 'mobx';

class Document {
  pageTitle = ''

  pageFavicon = ''

  constructor() {
    makeAutoObservable(this);
  }

  setPageTitle(title) {
    this.pageTitle = title;
  }

  // 修改浏览器小图标
  setFavicon(url) {
    this.pageFavicon = url;
    const faviconLink = document.querySelector('link[rel="icon"]');
    if (faviconLink) {
      faviconLink.setAttribute('href', url);
    } else {
      const newFaviconLink = document.createElement('link');
      newFaviconLink.setAttribute('rel', 'icon');
      newFaviconLink.setAttribute('href', url);
      document.head.appendChild(newFaviconLink);
    }
  }
}

export default new Document();
