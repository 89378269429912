/*
 * @Author: TANG
 * @Date: 2022-06-24 10:07:35
 * @LastEditors: TANG 15390913806@163.com
 * @LastEditTime: 2022-06-27 08:59:35
 */

/**
 * 成功码
 */
export const SUCCESS_CODE = 0;

/**
 * @param {*} name cookie的key
 * @param {*} value cookie的value
 * @param {*} expiryDate 存储时间
 */
export const setCookie = (name, value, expiryDate) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + expiryDate);
  document.cookie = `${name}=${value}; expires=${currentDate}`;
};

/**
 * @param {*} name cookie的key
 * @param {*} value cookie的value
 * @param {*} expiryDate 存储时间
 * @returns
 */
export const getCookie = (name, value, expiryDate) => {
  const arr = document.cookie.split('; ');
  for (let i = 0; i < arr.length; i += 1) {
    const arr2 = arr[i].split('=');
    if (arr2[0] === name) {
      return arr2[1];
    }
  }
  return '';
};

/**
 * @param {*} name cookie的key
 */
export const removeCookie = (name) => {
  setCookie(name, 1, -1);
};
