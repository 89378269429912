import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const BannerList = lazy(() => import('@/modules/recruitManagement/posterManages/banner'));
const MessageList = lazy(() => import('@/modules/recruitManagement/messageList'));
const FissionList = lazy(() => import('@/modules/recruitManagement/fissionManages/fissionList'));
const FissionRewardSetting = lazy(() => import('@/modules/recruitManagement/fissionManages/fissionRewardSetting'));
const FissionStatistics = lazy(() => import('@/modules/recruitManagement/fissionManages/fissionStatistics'));
const BlackList = lazy(() => import('@/modules/recruitManagement/fissionManages/blackList'));
const WithdrawalAudit = lazy(() => import('@/modules/recruitManagement/fissionManages/withdrawalAudit'));
const PosterManage = lazy(() => import('@/modules/recruitManagement/posterManages/posterManage'));
const CardManage = lazy(() => import('@/modules/recruitManagement/posterManages/cardManage'));
const OnlineLottery = lazy(() => import('@/modules/recruitManagement/lotteryManage/onlineLottery'));
const LotteryRecord = lazy(() => import('@/modules/recruitManagement/lotteryManage/lotteryRecord'));
const GiftList = lazy(() => import('@/modules/recruitManagement/giftManage/giftList'));
const GiftRules = lazy(() => import('@/modules/recruitManagement/giftManage/giftRules'));
const DocumentManage = lazy(() => import('@/modules/recruitManagement/documentManage'));
const NewsInformation = lazy(() => import('@/modules/recruitManagement/newsMannage/newsInformation'));

export default {
  path: 'recruitManagement',
  element: <Outlet />,
  options: {
    title: '运营管理',
    permissionKey: 'menu-recruitManagement',
    key: 'recruitManagement',
    icon: <Icon.FileTextOutlined />,
    link: '/recruitManagement',
    // allowAccess: true,
  },
  children: [
    {
      path: 'messageList',
      element: <Outlet />,
      options: {
        title: '消息管理',
        key: 'menu-recruitOperation-messageList',
        icon: <Icon.FileOutlined />,
        link: '/recruitManagement/messageList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="消息管理" permissionKey="menu-recruitOperation-messageList" component={<MessageList />} />,
        },
      ],
    }, {
      path: 'posterManages',
      element: <Outlet />,
      options: {
        title: '海报管理',
        key: 'menu-recruitManagement-posterManages',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitManagement/posterManages',
        allowAccess: true,
      },
      children: [
        {
          path: 'posterManage',
          element: <Outlet />,
          options: {
            title: '海报模板',
            key: 'menu-recruitManagement-posterManage',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/posterManages/posterManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="海报模板" permissionKey="menu-recruitManagement-posterManage" component={<PosterManage />} />,
            },
          ],
        }, {
          path: 'banner',
          element: <Outlet />,
          options: {
            title: 'Banner',
            key: 'menu-recruitOperation-banner',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/posterManages/banner',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="Banner" permissionKey="menu-recruitOperation-banner" component={<BannerList />} />,
            },
          ],
        },
        {
          path: 'cardManage',
          element: <Outlet />,
          options: {
            title: '贺卡管理',
            key: 'menu-recruitManagement-cardManage',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/posterManages/cardManage',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="贺卡管理" permissionKey="menu-recruitManagement-cardManage" component={<CardManage />} />,
            },
          ],
        },
      ],
    }, {
      path: 'fissionManages',
      element: <Outlet />,
      options: {
        title: '裂变管理',
        key: 'menu-recruitManagement-fissionManages',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitManagement/fissionManages',
        // allowAccess: true,
      },
      children: [
        {
          path: 'fissionList',
          element: <Outlet />,
          options: {
            title: '裂变名单',
            key: 'menu-recruitManagement-fissionList',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/fissionManages/fissionList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="裂变名单" permissionKey="menu-recruitManagement-fissionList" component={<FissionList />} />,
            },
          ],
        },
        {
          path: 'fissionStatistics',
          element: <Outlet />,
          options: {
            title: '裂变统计',
            key: 'menu-recruitManagement-fissionStatistics',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/fissionManages/fissionStatistics',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="裂变统计" permissionKey="menu-recruitManagement-fissionStatistics" component={<FissionStatistics />} />,
            },
          ],
        },
        {
          path: 'fissionRewardSetting',
          element: <Outlet />,
          options: {
            title: '裂变奖励设置',
            key: 'menu-recruitManagement-fissionRewardSetting',

            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/fissionManages/fissionRewardSetting',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="裂变奖励设置" permissionKey="menu-recruitManagement-fissionRewardSetting" component={<FissionRewardSetting />} />,
            },
          ],
        },
        {
          path: 'blackList',
          element: <Outlet />,
          options: {
            title: '推广黑名单',
            key: 'menu-recruitManagement-blackList',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/fissionManages/blackList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="推广黑名单" permissionKey="menu-recruitManagement-blackList" component={<BlackList />} />,
            },
          ],
        },
        {
          path: 'withdrawalAudit',
          element: <Outlet />,
          options: {
            title: '提现审核',
            key: 'menu-recruitManagement-withdrawalAudit',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/fissionManages/withdrawalAudit',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="提现审核" permissionKey="menu-recruitManagement-withdrawalAudit" component={<WithdrawalAudit />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'lotteryManage',
      element: <Outlet />,
      options: {
        title: '抽奖管理',
        key: 'menu-recruitManagement-lotteryManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitManagement/lotteryManage',
        // allowAccess: true,
      },
      children: [
        {
          path: 'onlineLottery',
          element: <Outlet />,
          options: {
            title: '线上抽奖',
            key: 'menu-recruitManagement-lotteryManage-onlineLottery',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/lotteryManage/onlineLottery',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="线上抽奖" permissionKey="menu-recruitManagement-lotteryManage-onlineLottery" component={<OnlineLottery />} />,
            },
          ],
        }, {
          path: 'lotteryRecord',
          element: <Outlet />,
          options: {
            title: '抽奖记录',
            key: 'menu-recruitManagement-lotteryManage-lotteryRecord',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/lotteryManage/lotteryRecord',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="抽奖记录" permissionKey="menu-recruitManagement-lotteryManage-lotteryRecord" component={<LotteryRecord />} />,
            },
          ],
        },
      ],
    }, {
      path: 'giftManage',
      element: <Outlet />,
      options: {
        title: '礼品管理',
        key: 'menu-recruitManagement-giftManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitManagement/giftManage',
        // allowAccess: true,
      },
      children: [
        {
          path: 'giftList',
          element: <Outlet />,
          options: {
            title: '领礼品名单',
            key: 'menu-recruitManagement-giftManage-giftList',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/giftManage/giftList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="领礼品名单" permissionKey="menu-recruitManagement-giftManage-giftList" component={<GiftList />} />,
            },
          ],
        }, {
          path: 'giftRules',
          element: <Outlet />,
          options: {
            title: '礼品发放规则',
            key: 'menu-recruitManagement-giftManage-giftRules',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/giftManage/giftRules',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="礼品发放规则" permissionKey="menu-recruitManagement-giftManage-giftRules" component={<GiftRules />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'documentManage',
      element: <Outlet />,
      options: {
        title: '资料管理',
        key: 'menu-recruitManagement-documentManage',
        icon: <Icon.FileOutlined />,
        link: '/recruitManagement/documentManage',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="资料管理" permissionKey="menu-recruitManagement-documentManage" component={<DocumentManage />} />,
        },
      ],
    },
    {
      path: 'newsMannage',
      element: <Outlet />,
      options: {
        title: '官网管理',
        key: 'menu-newsMannage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitManagement/newsMannage',
        // allowAccess: true,
      },
      children: [
        {
          path: 'newsInformation',
          element: <Outlet />,
          options: {
            title: '新闻资讯',
            key: 'menu-newsMannage-newsInformation',
            icon: <Icon.FileOutlined />,
            link: '/recruitManagement/newsMannage/newsInformation',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="新闻资讯" permissionKey="menu-newsMannage-newsInformation" component={<NewsInformation />} />,
            },
          ],
        },
      ],
    },
  ],
};
