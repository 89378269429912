import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';

import authStore from '@stores/auth';
import {
  Button, Input, List, message, Modal,
} from 'antd';
import accountHttp from '@utils/accountHttp';
import { SUCCESS_CODE } from '@modules/login/loginConst';
import md5 from 'md5';
import { Header as MyHeader, ListModal } from '@/components';
import { storageKeys } from '@/constants';
import { StyledHeader } from './styles';
import BreadCrumb from './breadcrumb';
import { httpRequest } from '@/utils';
import LoginApi from '@/fetch/apis/loginApi';

const Header = () => {
  const [listModalVisible, setListModalVisible] = useState(false); // 租户选择弹框
  const [listModalData, setListModalData] = useState([]); // 租户选择弹框数据
  const [tenantId, setTenantId] = useState(''); // 选择的tenant
  const [pwdVisible, setPwdVisible] = useState(false); // 密码弹框
  const [pwdData, setPwdData] = useState(''); // 密码弹框数据
  const [topData, setTopData] = useState(''); // 顶部提成数据

  const handleLogout = () => {
    authStore.clearlogInfo();
    Cookies.remove(storageKeys.token);
    Cookies.remove(storageKeys.permissions);
  };
  const getIndexTop = async () => {
    const res = await httpRequest.post('admin/index/top', {});
    if (res.code === 0) {
      setTopData(res.data);
    }
  };
  useEffect(() => {
    // if (authStore.profile.source === 'RECRUIT') {
    //   getIndexTop();
    // }
  }, []);

  const changeTenant = async (item) => {
    setTenantId(item.value);
    const res = await accountHttp.get(`/changeTenant/${item.value}/pc`);
    if (res?.code !== SUCCESS_CODE) {
      if (res?.code === 2) {
        message.info('已是该主体,无需切换');
        setListModalVisible(false);
        return;
      }
      if (res?.code === 3) {
        setListModalVisible(false);
        setPwdVisible(true);
        return;
      }
      message.error(`切换失败，${res.msg}`);
      return;
    }
    Cookies.set(storageKeys.tenantCode, res.data.tenantCode);
    Cookies.set(storageKeys.token, res.data.token);
    const href = `${window.location.origin}${process.env.REACT_APP_ROUTER_BASENAME ? `/${process.env.REACT_APP_ROUTER_BASENAME}` : ''}/`;
    window.location.href = href;
  };

  const renderListItem = (item) => (
    <List.Item onClick={() => changeTenant(item)}>
      {item.label}
    </List.Item>
  );

  const handleChangeTenant = async () => {
    const tenantRes = await LoginApi.tenantSelect(authStore.profile.account);
    setListModalData(tenantRes?.data?.map((i) => ({
      label: i.label,
      value: i.value,
    })));
    setListModalVisible(true);
  };

  const login = async () => {
    if (!pwdData) {
      message.error('请输入密码');
      return;
    }
    const params = {
      loginType: 'pwd',
      account: authStore.profile.account,
      password: md5(pwdData),
      tenantId,
    };
    const res = await LoginApi.Login(params);
    if (res?.code !== SUCCESS_CODE) {
      message.error(`登录失败，${res.msg}`);
      return;
    }
    Cookies.set(storageKeys.tenantCode, res.data.tenantCode);
    Cookies.set(storageKeys.token, res.data.token);
    const href = `${window.location.origin}${process.env.REACT_APP_ROUTER_BASENAME ? `/${process.env.REACT_APP_ROUTER_BASENAME}` : ''}/`;
    window.location.href = href;
  };

  return (
    <StyledHeader>
      <BreadCrumb />
      <MyHeader
        tenantName={authStore.profile?.tenantName}
        username={authStore.profile?.username}
        avatar={authStore.profile?.avatar}
        topData={topData}
        onLogout={handleLogout}
        onChangeTenant={handleChangeTenant}
      />
      <ListModal
        title="选择登录主体"
        listModalVisible={listModalVisible}
        onCancel={() => { setListModalVisible(false); }}
        footer={[]}
        data={listModalData}
        renderItem={(item) => renderListItem(item)}
      />
      <Modal title="输入密码" width={300} visible={pwdVisible} onOk={login} onCancel={() => setPwdVisible(false)}>
        <div>
          <Input.Password value={pwdData} onChange={(e) => setPwdData(e.target.value)} />
        </div>
      </Modal>
    </StyledHeader>
  );
};

export default observer(Header);
