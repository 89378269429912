/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { DashboardOutlined } from '@ant-design/icons';
import { ProtectedRoute } from '@components';
import Watermark from 'antd-watermark';
import UserInfo from '@stores/userInfo';
import Cookies from 'js-cookie';
import { storageKeys } from '@/constants';
import { httpRequest, accountHttp } from '@/utils';
import brand from '@/assets/zd/brand.png';
import yp from '@/assets/zd/yp.png';
import yc from '@/assets/zd/yc.png';
import yh from '@/assets/zd/yh.png';

const Dashboard = () => {
  const [userName, setUserName] = useState('');
  const tenantCode = Cookies.get(storageKeys.tenantCode);
  const getInfo = async () => {
    const res = await accountHttp.get('/admin/account/currentUser/info');
    if (res.code === 0) {
      setUserName(res.data.userName);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Watermark content={`${UserInfo.profile.storeName} - ${UserInfo.profile.userName}`}>
      <div style={{
        width: '100%',
        minHeight: 700,
        backgroundColor: '#fff',
        paddingTop: 30,
      }}
      >
        <div style={{
          position: 'relative',
          height: '100%',
        }}
        >
          <img
            style={{
              maxHeight: 240,
              maxWidth: 1200,
              display: 'block',
              position: 'absolute',
              left: '27%',
              top: '30%',
            }}
            src={tenantCode === 'yp' ? yp
              : tenantCode === 'yh' ? yh
                : tenantCode === 'zpwl' ? yc : brand}
            alt=""
          />
        </div>
      </div>
    </Watermark>
  );
};

export default {
  index: true,
  element: <ProtectedRoute allowAccess title="首页" component={<Dashboard />} />,
  options: {
    title: '首页',
    key: 'dashboard',
    icon: <DashboardOutlined />,
    link: '/',
    allowAccess: true,
  },
};
