import React, { lazy } from 'react';
import { ProtectedRoute } from '@components';
import UserEdit from '@modules/setting/userInfo/userEdit/userEdit';
import UserNew from '@modules/setting/userInfo/userNew/userNew';

const Record = lazy(() => import('./record'));

export default [{
  index: true,
  element: <ProtectedRoute title="用户管理" permissionKey="menu-baseInfo-userManage" component={<Record />} />,
  options: {
    title: '用户管理',
    permissionKey: 'menu-baseInfo-userManage',
    key: '',
    icon: null,
    link: '/setting/userInfo',
    displayInMenu: false,
  },
}, {
  path: 'create',
  element: <ProtectedRoute title="新建用户" permissionKey="menu-baseInfo-userManage" component={<UserNew />} />,
  options: {
    title: '新建用户',
    permissionKey: 'menu-baseInfo-userManage',
    key: '',
    icon: null,
    link: '/setting/userInfo/create',
    displayInMenu: false,
  },
}, {
  path: ':userId/edit',
  element: <ProtectedRoute title="编辑用户" permissionKey="menu-baseInfo-userManage" component={<UserEdit />} />,
  options: {
    title: '编辑用户',
    permissionKey: 'menu-baseInfo-userManage',
    key: '',
    icon: null,
    link: '/setting/userInfo/:userId/edit',
    displayInMenu: false,
  },
}];
