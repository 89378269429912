import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const AttendanceGroup = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceGroup'));
const AttendanceCreact = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceGroup/groupCreact'));
const AttendanceEdit = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceGroup/groupEdit'));
const SettlementMode = lazy(() => import('@/modules/attendanceManage/attendanceSetting/settlementMode'));
const CreactModeInfo = lazy(() => import('@/modules/attendanceManage/attendanceSetting/settlementMode/creactSettleMode'));
const EditModeInfo = lazy(() => import('@/modules/attendanceManage/attendanceSetting/settlementMode/settleModeEdit'));
const CheckInList = lazy(() => import('@/modules/attendanceManage/attendanceDetail/attendanceRoster'));
const CreactUserInfo = lazy(() => import('@/modules/attendanceManage/attendanceDetail/attendanceRoster/createUserInfo'));
const EditUserInfo = lazy(() => import('@/modules/attendanceManage/attendanceDetail/attendanceRoster/userInfoEdit'));
const AttendanceRecord = lazy(() => import('@/modules/attendanceManage/attendanceDetail/attendanceRecord'));
const AttendanceAddress = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceAddress'));
const AddressCreact = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceAddress/addressCreact'));
const AddressEdit = lazy(() => import('@/modules/attendanceManage/attendanceSetting/attendanceAddress/addressEdit'));
const RiskControlSetting = lazy(() => import('@/modules/attendanceManage/riskControlManage/riskControlSetting'));
const RiskControlAudit = lazy(() => import('@/modules/attendanceManage/riskControlManage/riskControlAudit'));

export default {
  path: 'attendanceManage',
  element: <Outlet />,
  options: {
    title: '打卡管理',
    permissionKey: 'menu-attendanceManage',
    key: 'menu-attendanceManage',
    icon: <Icon.ContactsOutlined />,
    link: '/attendanceManage',
    // allowAccess: true,
  },
  children: [
    {
      path: 'attendanceSetting',
      element: <Outlet />,
      options: {
        title: '考勤设置',
        key: 'menu-attendanceManage-attendanceSetting',
        icon: <Icon.FolderOpenOutlined />,
        link: '/attendanceManage/attendanceSetting',
        // allowAccess: true,
      },
      children: [
        {
          path: 'attendanceGroup',
          element: <Outlet />,
          options: {
            title: '考勤组',
            key: 'menu-attendanceManage-attendanceSetting-attendanceGroup',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/attendanceSetting/attendanceGroup',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="考勤组" permissionKey="menu-attendanceManage-attendanceSetting-attendanceGroup" component={<AttendanceGroup />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增考勤组" permissionKey="menu-attendanceManage-attendanceSetting-attendanceGroup" component={<AttendanceCreact />} />,
            },
            {
              path: ':groupId/edit',
              element: <ProtectedRoute title="编辑考勤组" permissionKey="menu-attendanceManage-attendanceSetting-attendanceGroup" component={<AttendanceEdit />} />,
            },
          ],
        }, {
          path: 'settlementMode',
          element: <Outlet />,
          options: {
            title: '结算模式',
            key: 'menu-attendanceManage-attendanceSetting-settlementMode',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/attendanceSetting/settlementMode',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="结算模式" permissionKey="menu-attendanceManage-attendanceSetting-settlementMode" component={<SettlementMode />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增结算模式" permissionKey="menu-attendanceManage-attendanceSetting-settlementMode" component={<CreactModeInfo />} />,
            },
            {
              path: ':settleModeId/edit',
              element: <ProtectedRoute title="编辑结算模式" permissionKey="menu-attendanceManage-attendanceSetting-settlementMode" component={<EditModeInfo />} />,
            },
          ],
        },
        {
          path: 'attendanceAddress',
          element: <Outlet />,
          options: {
            title: '考勤地址',
            key: 'menu-attendanceManage-attendanceSetting-attendanceAddress',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/attendanceSetting/attendanceAddress',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="考勤地址" permissionKey="menu-attendanceManage-attendanceSetting-attendanceAddress" component={<AttendanceAddress />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增考勤地点" permissionKey="menu-attendanceManage-attendanceSetting-attendanceAddress" component={<AddressCreact />} />,
            },
            {
              path: ':addressId/edit',
              element: <ProtectedRoute title="编辑考勤地点" permissionKey="menu-attendanceManage-attendanceSetting-attendanceAddress" component={<AddressEdit />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'attendanceDetail',
      element: <Outlet />,
      options: {
        title: '考勤明细',
        key: 'menu-attendanceManage-attendanceDetail',
        icon: <Icon.FolderOpenOutlined />,
        link: '/attendanceManage/attendanceDetail',
        // allowAccess: true,
      },
      children: [
        {
          path: 'attendanceRoster',
          element: <Outlet />,
          options: {
            title: '打卡名单',
            key: 'menu-attendanceManage-attendanceDetail-attendanceRoster',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/attendanceDetail/attendanceRoster',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="打卡名单" permissionKey="menu-attendanceManage-attendanceDetail-attendanceRoster" component={<CheckInList />} />,
            },
            {
              path: 'create',
              element: <ProtectedRoute title="新增员工" permissionKey="menu-attendanceManage-attendanceDetail-attendanceRoster" component={<CreactUserInfo />} />,
            },
            {
              path: ':userId/edit',
              element: <ProtectedRoute title="编辑员工" permissionKey="menu-attendanceManage-attendanceDetail-attendanceRoster" component={<EditUserInfo />} />,
            },
          ],
        },
        {
          path: 'attendanceRecord',
          element: <Outlet />,
          options: {
            title: '考勤记录',
            key: 'menu-attendanceManage-attendanceDetail-attendanceRecord',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/attendanceDetail/attendanceRecord',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="考勤记录" permissionKey="menu-attendanceManage-attendanceDetail-attendanceRecord" component={<AttendanceRecord />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'riskControlManage',
      element: <Outlet />,
      options: {
        title: '风控管理',
        key: 'menu-attendanceManage-riskControlManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/attendanceManage/riskControlManage',
        // allowAccess: true,
      },
      children: [
        {
          path: 'riskControlSetting',
          element: <Outlet />,
          options: {
            title: '风控配置',
            key: 'menu-attendanceManage-riskControlManage-riskControlSetting',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/riskControlManage/riskControlSetting',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="风控配置" permissionKey="menu-attendanceManage-riskControlManage-riskControlSetting" component={<RiskControlSetting />} />,
            },
          ],
        },
        {
          path: 'attendanceAudit',
          element: <Outlet />,
          options: {
            title: '考勤审核',
            key: 'menu-attendanceManage-riskControlManage-attendanceAudit',
            icon: <Icon.FileOutlined />,
            link: '/attendanceManage/riskControlManage/attendanceAudit',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="考勤审核" permissionKey="menu-attendanceManage-riskControlManage-attendanceAudit" component={<RiskControlAudit />} />,
            },
          ],
        },
      ],
    },
  ],
};
