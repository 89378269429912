import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { ProtectedRoute } from '@components';

const NewestList = lazy(() => import('@/modules/recruitmentManage/listManagement/newestStatus'));
const RegistraList = lazy(() => import('@/modules/recruitmentManage/listManagement/registrationList'));
const InterviewList = lazy(() => import('@/modules/recruitmentManage/listManagement/interviewList'));
const WaitingList = lazy(() => import('@/modules/recruitmentManage/listManagement/waitingList'));
const ResignationList = lazy(() => import('@/modules/recruitmentManage/listManagement/resignationList'));
const AppointmentList = lazy(() => import('@/modules/recruitmentManage/bookingManage/appointmentList'));
const ReservationOrder = lazy(() => import('@/modules/recruitmentManage/bookingManage/reservationOrder'));
const RecruitOrder = lazy(() => import('@/modules/recruitmentManage/recruitOrder'));
const OrderInfo = lazy(() => import('@/modules/recruitmentManage/orderInfo'));
const ComplaintList = lazy(() => import('@/modules/recruitmentManage/complaintList'));
const CommissionAccount = lazy(() => import('@/modules/recruitmentManage/myCommission/commissionAccount'));
const CommissionDetails = lazy(() => import('@/modules/recruitmentManage/myCommission/commissionDetails'));

export default {
  path: 'recruitmentManage',
  element: <Outlet />,
  options: {
    title: '招聘管理',
    permissionKey: 'menu-listManagement',
    key: 'listManagement',
    icon: <Icon.FileTextOutlined />,
    link: '/recruitmentManage',
    allowAccess: true,
  },
  children: [
    {
      path: 'recruitOrder',
      element: <Outlet />,
      options: {
        title: '招聘订单',
        key: 'menu-recruitManagement-recruitOrder',
        icon: <Icon.FileOutlined />,
        link: '/recruitmentManage/recruitOrder',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="招聘订单" permissionKey="menu-recruitManagement-recruitOrder" component={<RecruitOrder />} />,
        },
        {
          path: ':orderId/edit',
          element: <ProtectedRoute title="查看订单信息" permissionKey="menu-recruitManagement-recruitOrder" component={<OrderInfo />} />,
        },
      ],
    },
    {
      path: 'listManagement',
      element: <Outlet />,
      options: {
        title: '名单管理',
        key: 'menu-recruitmentManage-listManagement',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitmentManage/listManagement',
        allowAccess: true,
      },
      children: [
        {
          path: 'newestStatus',
          element: <Outlet />,
          options: {
            title: '最新状态',
            key: 'menu-listManagement-newestStatus',
            icon: null,
            link: '/recruitmentManage/listManagement/newestStatus',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="最新状态" permissionKey="menu-listManagement-newestStatus" component={<NewestList />} />,
            },
          ],
        },
        {
          path: 'registrationList',
          element: <Outlet />,
          options: {
            title: '报名名单',
            key: 'menu-listManagement-registrationList',
            icon: null,
            link: '/recruitmentManage/listManagement/registrationList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="报名名单" permissionKey="menu-listManagement-registrationList" component={<RegistraList />} />,
            },
          ],
        },
        {
          path: 'interviewList',
          element: <Outlet />,
          options: {
            title: '面试名单',
            key: 'menu-listManagement-interviewList',
            icon: null,
            link: '/recruitmentManage/listManagement/interviewList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="面试名单" permissionKey="menu-listManagement-interviewList" component={<InterviewList />} />,
            },
          ],
        },
        {
          path: 'waitingList',
          element: <Outlet />,
          options: {
            title: '待入职名单',
            key: 'menu-listManagement-waitingList',
            icon: null,
            link: '/recruitmentManage/listManagement/waitingList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="待入职名单" permissionKey="menu-listManagement-waitingList" component={<WaitingList />} />,
            },
          ],
        },
        {
          path: 'resignationList',
          element: <Outlet />,
          options: {
            title: '在离职名单',
            key: 'menu-listManagement-resignationList',
            icon: null,
            link: '/recruitmentManage/listManagement/resignationList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="在离职名单" permissionKey="menu-listManagement-resignationList" component={<ResignationList />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'bookingManage',
      element: <Outlet />,
      options: {
        title: '预约管理',
        key: 'menu-recruitManagement-bookingManage',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitmentManage/bookingManage',
        // allowAccess: true,
      },
      children: [
        {
          path: 'reservationOrder',
          element: <Outlet />,
          options: {
            title: '预约订单',
            key: 'menu-recruitManagement-reservationOrder',
            icon: null,
            link: '/recruitmentManage/bookingManage/reservationOrder',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="预约订单" permissionKey="menu-recruitManagement-reservationOrder" component={<ReservationOrder />} />,
            },
          ],
        }, {
          path: 'appointmentList',
          element: <Outlet />,
          options: {
            title: '预约名单',
            key: 'menu-recruitManagement-appointmentList',
            icon: null,
            link: '/recruitmentManage/bookingManage/appointmentList',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="预约名单" permissionKey="menu-recruitManagement-appointmentList" component={<AppointmentList />} />,
            },
          ],
        },
      ],
    },
    {
      path: 'complaintList',
      element: <Outlet />,
      options: {
        title: '会员投诉',
        key: 'menu-complaintManagement-list',
        icon: <Icon.FileOutlined />,
        link: '/recruitmentManage/complaintList',
      },
      children: [
        {
          index: true,
          element: <ProtectedRoute title="会员投诉" permissionKey="menu-complaintManagement-list" component={<ComplaintList />} />,
        },
      ],
    },
    {
      path: 'myCommission',
      element: <Outlet />,
      options: {
        title: '我的提成',
        key: 'menu-recruitmentManage-myCommission',
        icon: <Icon.FolderOpenOutlined />,
        link: '/recruitmentManage/myCommission',
        allowAccess: true,
      },
      children: [
        {
          path: 'commissionAccount',
          element: <Outlet />,
          options: {
            title: '提成核算',
            key: 'menu-myReward-count',
            icon: <Icon.FileOutlined />,
            link: '/recruitmentManage/myCommission/commissionAccount',
            // allowAccess: true,
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="提成核算" permissionKey="menu-myReward-count" component={<CommissionAccount />} />,
            },
          ],
        },
        {
          path: 'commissionDetails',
          element: <Outlet />,
          options: {
            title: '提成明细',
            key: 'menu-myReward-detail',
            icon: <Icon.FileOutlined />,
            link: '/recruitmentManage/myCommission/commissionDetails',
          },
          children: [
            {
              index: true,
              element: <ProtectedRoute title="提成明细" permissionKey="menu-myReward-detail" component={<CommissionDetails />} />,
            },
          ],
        },
      ],
    },
  ],
};
